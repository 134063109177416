import React, {Component} from 'react';
import './center.less';
import {Route} from "react-router-dom";

import MyProduct from './my-product';
import MyAssets from './my-asset';
import MyRpq from './my-rpq';
import MyKyc from './my-kyc';
import MyInfo from './my-info';
import SafePassword from './safe-password';
import ProductDetail from './product-detail';
import AssetDetail from './asset-Detail/asset-Detail'

import {User} from "../../service/user";
import {GetIspUserInfoForApp, GetIspUserManageCenterForApp} from "../../js/http-serve";
import {api} from "../../js/http-serve/ajax";
import {CenterModel} from "./center.model";
import {Avatar, Calendar, Tooltip} from "antd";

class Center extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navActive: 1,
            info: {},
            center: new CenterModel(),
            isShowCalendar: false,
        };
    }

    navigate = (index) => {
        this.getCenter()
        this.setState({
            navActive: index
        }, () => {
            if (index == 1) {
                this.props.history.push('/center/my-product')
            }
            if (index == 2) {
                this.props.history.push('/center/my-asset')
            }
            if (index == 3) {
                this.getInfo()
                this.props.history.push('/center/my-info')
            }
            if (index == 4) {
                this.props.history.push('/center/my-rpq')
            }
            if (index == 5) {
                this.props.history.push('/center/my-kyc')
            }
            if (index == 6) {
                this.props.history.push('/center/safe-password')
            }
        });

    };

    async componentDidMount() {
        this.listener = this.urlFit();
        api.setToken(User.getUser().Token);
        try {
            let info = await GetIspUserInfoForApp();
            let center = await GetIspUserManageCenterForApp();
            this.setState({
                info,
                center
            })
        } catch (e) {
        }
    }

    getInfo = async () => {
        let info = await GetIspUserInfoForApp();
        this.setState({
            info
        })
    }


    getCenter = async (cb) => {
        let center = await GetIspUserManageCenterForApp();
        this.setState({
            center
        })
        cb && await cb()
    }


    componentWillMount() {
        let i = 0;
        if (this.props.history.location.pathname.indexOf('/center/my-product') > -1) {
            i = 1;
        } else if (this.props.history.location.pathname.indexOf('/center/my-asset') > -1) {
            i = 2;
        } else if (this.props.history.location.pathname.indexOf('/center/my-info') > -1) {
            i = 3;
        } else if (this.props.history.location.pathname.indexOf('/center/my-rpq') > -1) {
            i = 4;
        } else if (this.props.history.location.pathname.indexOf('/center/my-kyc') > -1) {
            i = 5;
        } else if (this.props.history.location.pathname.indexOf('/center/safe-password') > -1) {
            i = 6;
        }
        this.setState({
            navActive: i
        });
    }

    componentWillUnmount() {
        this.listener();
    }

    urlFit = () => {
        let i = 1;
        return this.props.history.listen((location, action) => {
            if (location.pathname.indexOf('/center/my-product') > -1) {
                i = 1;
            } else if (location.pathname.indexOf('/center/my-asset') > -1) {
                i = 2;
            } else if (location.pathname.indexOf('/center/my-info') > -1) {
                i = 3;
            } else if (location.pathname.indexOf('/center/my-rpq') > -1) {
                i = 4;
            } else if (location.pathname.indexOf('/center/my-kyc') > -1) {
                i = 5;
            } else if (location.pathname.indexOf('/center/safe-password') > -1) {
                i = 6;
            }
            this.setState({
                navActive: i
            });
        });

    };

    showCalendar() {
        this.setState({
            isShowCalendar: !this.state.isShowCalendar
        })
    }

    render() {
        const {navActive, center} = this.state;
        return (
            <div className="center">
                <img src={require('../../images/center-bg.png')}/>
                <div className="wrap">
                    <div className="left">
                        <div className="top">
                            <div className="advtar-wrap">
                                <Avatar size={54} icon="user"/>
                                <span className={'name'}>{center.UserName}</span>
                            </div>
                            <div className="tag">
                                <Tooltip
                                    overlayClassName={'calendar'}
                                    placement="bottom"
                                    title={
                                        <Calendar fullscreen={false}/>}
                                >
                                    <img
                                        src={require('../../images/icon1.png')}/>
                                </Tooltip>
                                <Tooltip
                                    placement={'bottom'}
                                    title={
                                        <span>
                                            <span>{!this.state.center.EmployeeId && "您暂无客户经理"}</span>
                                            <span>{this.state.center.EmployeeId &&
                                            <span>您的客户经理:&nbsp;&nbsp;{this.state.center.EmployeeName}<br/>联系方式:&nbsp;&nbsp;{this.state.center.EmployeeMobile}</span>}</span>
                                        </span>
                                    }
                                >
                                    <img src={require('../../images/icon2.png')}/>
                                </Tooltip>
                                <Tooltip
                                    placement={'bottom'}
                                    title={'联系我们：400-6277-108'}>
                                    <img src={require('../../images/icon3.png')}/>
                                </Tooltip>

                            </div>
                        </div>
                        <ul className="sec-nav">
                            <li onClick={this.navigate.bind(this, 1)}
                                className={navActive == 1 ? 'active' : ''}>我的产品<i></i></li>
                            <li onClick={this.navigate.bind(this, 2)}
                                className={navActive == 2 ? 'active' : ''}>我的资产<i></i></li>
                            <li onClick={this.navigate.bind(this, 3)}
                                className={navActive == 3 ? 'active' : ''}>个人资料<i></i></li>
                            <li onClick={this.navigate.bind(this, 4)}
                                className={navActive == 4 ? 'active' : ''}>风险测评<i></i></li>
                            <li onClick={this.navigate.bind(this, 5)}
                                className={navActive == 5 ? 'active' : ''}>投资者认证<i></i></li>
                            <li onClick={this.navigate.bind(this, 6)}
                                className={navActive == 6 ? 'active' : ''}>安全设置<i></i></li>
                        </ul>
                    </div>
                    <div className="right">
                        <Route exact path="/center"
                               render={(routeProps) => {
                                   return <MyProduct {...routeProps} center={center}/>
                               }}/>
                        <Route exact path="/center/my-product" render={(routeProps) => {
                            return <MyProduct {...routeProps} center={center}/>
                        }}/>
                        <Route exact path="/center/my-asset" render={(routeProps) => {
                            return <MyAssets {...routeProps} center={center}/>
                        }}/>
                        <Route exact path="/center/my-asset/:id" render={(routeProps) => {
                            return <AssetDetail {...routeProps} center={center}/>
                        }}/>
                        <Route exact path="/center/my-info" render={(routeProps) => {
                            return <MyInfo {...routeProps} center={center} getCenter={this.getCenter}
                                           getInfo={this.getInfo}
                                           info={this.state.info}/>
                        }}/>
                        <Route exact path="/center/my-product/:id" render={(routeProps) => {
                            return <ProductDetail {...routeProps} center={center}/>
                        }}/>
                        <Route exact path="/center/my-kyc" render={(routeProps) => {
                            return <MyKyc {...routeProps} center={center} getCenter={this.getCenter}/>
                        }}/>
                        <Route exact path="/center/my-rpq" render={(routeProps) => {
                            return <MyRpq {...routeProps} center={center} getCenter={this.getCenter}/>
                        }}/>
                      <Route exact path="/center/safe-password" render={(routeProps) => {
                        return <SafePassword {...routeProps} center={center} getCenter={this.getCenter}/>
                      }}/>
                    </div>
                </div>
            </div>

        );
    }
}

export default Center;
