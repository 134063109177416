export class CenterModel {
    AssessmentResultInfoId
    ConfigurationAssistant
    CustomerServerPhone
    EmployeeId
    EmployeeMobile
    EmployeeName
    EmployeeStatus
    HasBankCard
    IcId
    IcInfo
    IcStatus
    Id
    IsCertification
    IsConfigurationAssistant
    IsPsychological
    MemberCode
    MemberLevel
    Psychological
    QuestName
    RiskLevel
    RpqInfo
    RpqStatus
    StockMoney
    StockShare
    TotalIncome
    TotalInvestMoney
    UserName
    YesterdayIncome


    constructor() {
        this.AssessmentResultInfoId = '';
        this.ConfigurationAssistant = '';
        this.CustomerServerPhone = '';
        this.EmployeeId = '';
        this.EmployeeMobile = '';
        this.EmployeeName = '';
        this.EmployeeStatus = 0;
        this.HasBankCard = false;
        this.IcId = '';
        this.IcInfo = '';
        this.IcStatus = 0;
        this.Id = '';
        this.IsCertification = false;
        this.IsConfigurationAssistant = false;
        this.IsPsychological = false;
        this.MemberCode = '';
        this.MemberLevel = '';
        this.Psychological = '';
        this.QuestName = '';
        this.RiskLevel = '';
        this.RpqInfo = '';
        this.RpqStatus = 0;
        this.StockMoney = 0;
        this.StockShare = 0;
        this.TotalIncome = 0;
        this.TotalInvestMoney = 0;
        this.UserName = '';
        this.YesterdayIncome = 0;
    }
}

export class AssetReport {
    StockAssetAllocationResult
    StockMoney
    StockShare
    TotalAssetAllocationResult
    TotalMoney

    constructor() {
        this.StockAssetAllocationResult = []
        this.StockMoney = '';
        this.StockShare = '';
        this.TotalAssetAllocationResult = []
        this.TotalMoney = ''
    }
}


export class Statement {
    TotalExpense;
    TotalIncome;
    StatementOfAccountList;

    constructor() {
        this.TotalExpense = 0
        this.TotalIncome = 0
        this.StatementOfAccountList = {
            PageSize: 0,
            PageIndex: 0,
            TotalCount: 0,
            Data: []
        }
    }
}


export class ProductDetailModel {
    BankAccount
    BankAttribution
    BankOfDeposit
    ContractCode
    CustomerId
    IncomeModel
    IncomeModelId
    IncomeModelPhasesId
    MaturityDate
    OriginalStockMoney
    OriginalStockShare
    ProductName
    StockMoney
    StockShare
    TradeFlows
    TradeId
    ValueDate


    constructor() {
        this.BankAccount = '';
        this.BankAttribution = '';
        this.BankOfDeposit = '';
        this.ContractCode = '';
        this.CustomerId = '';
        this.IncomeModel = null;
        this.IncomeModelId = '';
        this.IncomeModelPhasesId = '';
        this.MaturityDate = '';
        this.OriginalStockMoney = 0;
        this.OriginalStockShare = 0;
        this.ProductName = '';
        this.StockMoney = 0;
        this.StockShare = 0;
        this.TradeFlows = [];
        this.TradeId = '';
        this.ValueDate = '';
    }
}

export class Asset{

}
