import React, {Component} from 'react';
import './index.less';
import {GetMyTradeDetail} from "../../../js/http-serve";
import {User} from "../../../service/user";
import {api} from "../../../js/http-serve/ajax";
import {ProductDetailModel} from "../center.model";
import moment from "moment";
import * as accounting from "accounting";
import {getFrequency, getIsFloat, period} from "../../../utils";

class ProductDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detail: new ProductDetailModel()
        };
        this.TradeId = props.match.params.id
    }

    componentDidMount() {
        this.getMyTradeDetail()
    }


    getMyTradeDetail = async () => {
        let detail = await GetMyTradeDetail({TradeId: this.TradeId})
        this.setState({
            detail
        })
    }

    render() {

        return (
            <div className="product-detail">
                <h1>{this.state.detail.ProductName}</h1>
                <ul className="ele">
                    <li>
                        <span className="txt">成立日</span>
                        <span className="val">{this.state.detail.ValueDate ? moment(this.state.detail.ValueDate).format('YYYY-MM-DD') : '--'}</span>
                    </li>
                    <li>
                        <span className="txt">到期日(不含当日)</span>
                        <span className="val">{this.state.detail.ValueDate ? moment(this.state.detail.MaturityDate).format('YYYY-MM-DD') : '--'}</span>
                    </li>
                    <li>
                        <span className="txt">初始存量金额</span>
                        <span
                            className="val">{accounting.formatNumber(this.state.detail.OriginalStockMoney, 2)}万元</span>
                    </li>
                    <li>
                        <span className="txt">存量金额</span>
                        <span className="val">{accounting.formatNumber(this.state.detail.StockMoney, 2)}万元</span>
                    </li>
                    <li>
                        <span className="txt">户名</span>
                        <span className="val">{this.state.detail.BankAttribution}</span>
                    </li>
                    <li>
                        <span className="txt">银行卡号</span>
                        <span className="val">{this.state.detail.BankAccount}</span>
                    </li>
                    <li>
                        <span className="txt">开户行</span>
                        <span className="val">{this.state.detail.BankOfDeposit}</span>
                    </li>
                    <li>
                        <span className="txt">合同编号</span>
                        <span className="val">{this.state.detail.ContractCode || '--'}</span>
                    </li>
                </ul>
                {
                    this.state.detail.IncomeModel &&
                    <>
                        <div className="t-t">
                            <span>{this.state.detail.IncomeModel.TypeId}类</span>
                            <span>{this.state.detail.IncomeModel.StartAmount}万元以上</span>
                        </div>
                        <table className="table">
                            <thead>
                            <tr>
                                <th>阶段</th>
                                <th>认购期限</th>
                                <th>付息频次</th>
                                <th>是否浮动</th>
                                <th>收益率</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.state.detail.IncomeModel.IncomeModelPhases.map((item, i) => {
                                    return (
                                        <tr key={item.IncomeModelId}>
                                            <td>{item.PhasesId}</td>
                                            <td>{period(item.Period, item.PeriodUnit)}</td>
                                            <td>{getFrequency(item.Frequency)}</td>
                                            <td>{getIsFloat(item.IsFloat)}</td>
                                            <td className="profit">{item.StartIncome}</td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </>
                }
            </div>

        );
    }
}


export default ProductDetail;
