import React, {Component} from 'react';
import './index.less';


class ClassifyTitle extends Component {

    static defaultProps = {
        peach: false
    }

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className={`classify-title ${this.props.className ? this.props.className : ''}`}>
                <div className="frame">
                    <div className={`cn ${this.props.peach ? 'peach' : ''}`}>{this.props.cn}</div>
                    <div className={'en'}>{this.props.en}</div>
                    <div className="line"></div>
                </div>

            </div>

        );
    }
}

export default ClassifyTitle;
