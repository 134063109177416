import React, {Component} from 'react';
import {withRouter} from "react-router-dom";

import './productDetail.less';
import {InputNumber, Pagination, Progress, Tooltip, Modal, Empty} from "antd";
import {GetIspUserManageCenterForApp, GetToken, GetWebSiteProductDetail} from "../../../js/http-serve";
import {api} from "../../../js/http-serve/ajax";
import {ProductDetailModel} from "./productDetail.model";
import {formatAmount, formatMaxProfitRate} from "../../../utils";
import config from '../../../js/config'
import {User} from "../../../service/user";
import {CenterModel} from "../../center/center.model";

class ProductDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productDetail: new ProductDetailModel(),
            center: new CenterModel()
        };
        this.ProductId = ''
    }

    componentWillMount() {
        if (!User.isLogin()) {
            return this.props.history.replace('/login')
        }
        this.ProductId = window.location.href.split('/product/productDetail/')[1]
    }

    componentDidMount() {
        document.scrollingElement.scrollTop = 0;
        this.getProductDetail();
        this.getCenter()
    }

    getCenter = async () => {
        let center = await GetIspUserManageCenterForApp();
        this.setState({center})
    };

    getProductDetail = async () => {
        let productId = {
            ProductId: this.ProductId
        };
        let detail = await GetWebSiteProductDetail(productId);
        this.setState({
            productDetail: detail
        })

    };


    showModal = () => {
        //您暂无客户经理,请联系400电话
        Modal.info({
            title: '联系客户经理',
            content: `${this.state.center.EmployeeId ? ('您的客户经理联系方式为' + (this.state.center.EmployeeMobile ? this.state.center.EmployeeMobile : '--')) : '您暂无客户经理，请联系客服电话400-6277-108'}`
        })
    };

    render() {
        let {productDetail} = this.state;
        return (
            <div className="productDetailAll">
                <div className='container'>
                    <div className="topBg"></div>
                    <div className="header">
                        <div className="headerLeft">
                            <div className="titleBox">
                                <p className="title">{productDetail.ProductName}</p>
                                {
                                    productDetail.ProductTypeDesc && productDetail.ProductTypeDesc.length > 0 &&
                                    <div
                                        className="desc">{productDetail.ProductTypeDesc[4]}</div>
                                }
                            </div>
                            <div className="infoMain">
                                <div className="info">
                                    <p className="val spec">
                                      {
                                        productDetail.IsFloat ?
                                          <span
                                            className="tdTxt">浮动</span>
                                          :
                                          <span
                                            className="tdTxt">{formatMaxProfitRate(productDetail.MinProfitRate, productDetail.MaxProfitRate)}</span>
                                      }
                                    </p>
                                    <p className="key">业绩比较基准</p>
                                </div>
                                <div className="info">
                                    <p className="val">{productDetail.InvestmentHorizon}</p>
                                    <p className="key">投资期限</p>
                                </div>
                                <div className="info">
                                    <p className="val">{productDetail.InvestMin}万元</p>
                                    <p className="key">起投资金</p>
                                </div>
                                <div className="info">
                                    <p className="val">{productDetail.IncomeModels.length > 0 ? productDetail.IncomeModels[0].Frequencys : '--'}</p>
                                    <p className="key">付息方式</p>
                                </div>
                            </div>
                            <div className="progress">
                                <p className="key">募集进度</p>
                                <Tooltip
                                    title={'募集进度' + ((productDetail.IssueCurrentMoney / productDetail.IssueQuota) * 100).toFixed(2) + '%'}>
                                    <Progress
                                        percent={(productDetail.IssueCurrentMoney / productDetail.IssueQuota) * 100}
                                        size="small" showInfo={false}
                                        strokeColor="#EDB35B"/>
                                </Tooltip>
                            </div>
                        </div>
                        <div className="headerRight">
                            <div className="table">
                                <div className="th">
                                    <span className="thTxt">认购金额</span>
                                    <span className="thTxt">业绩比较基准</span>
                                </div>
                                <div className="td">
                                    {this.state.productDetail.IncomeModels.map((el, i) => {
                                        return (
                                            <div className='tdVal' key={i + 'incomemodule'}>
                                                <span
                                                    className="tdTxt">{formatAmount(el.StartAmount, el.EndAmount)}</span>
                                                {
                                                  el.IsFloat ?
                                                    <span
                                                      className="tdTxt">浮动</span>
                                                    :
                                                    <span
                                                      className="tdTxt">{formatMaxProfitRate(el.MinIncome, el.MaxIncome)}</span>
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="contactBtn" onClick={() => {
                                this.showModal()
                            }}>联系客户经理
                            </div>
                        </div>
                    </div>
                    <div className="main">
                        <div className="mainLeft">
                            <p className='title'>基本信息</p>
                            <p className='desc'>{productDetail.Slogan}</p>
                            <div className="table">
                                <div className="tr">
                                    <div className="th">产品类型</div>
                                    <div className="td">{productDetail.ProductTypeDesc[0]}</div>
                                </div>
                                <div className="tr">
                                    <div className="th">发行通道</div>
                                    <div className="td">{productDetail.ProductTypeDesc[4]}</div>
                                </div>
                                <div className="tr">
                                    <div className="th">基础资产</div>
                                    <div className="td">{productDetail.ProductTypeDesc[1]}</div>
                                </div>
                                <div className="tr">
                                    <div className="th">收益类型</div>
                                    <div className="td">{productDetail.ProductTypeDesc[2]}</div>
                                </div>
                                <div className="tr">
                                    <div className="th">认购起点</div>
                                    <div className="td">{productDetail.InvestMin}万元</div>
                                </div>
                                <div className="tr">
                                    <div className="th">递增金额</div>
                                    <div className="td">{productDetail.IncreasingAmount}万元</div>
                                </div>
                                <div className="tr">
                                    <div className="th">投资期限</div>
                                    <div className="td">{productDetail.InvestmentHorizon}</div>
                                </div>
                            </div>
                            {productDetail.Brief && <>
                                <p className='title'>产品简要</p>
                                <p className='content' dangerouslySetInnerHTML={{
                                    __html: productDetail.Brief
                                }}>
                                </p>
                            </>}

                            {productDetail.HighLight &&
                            <>
                                <p className='title'>产品亮点</p>
                                <p className='content' dangerouslySetInnerHTML={{
                                    __html: productDetail.HighLight
                                }}>
                                </p>
                            </>
                            }
                            {productDetail.RiskControl &&
                            <>
                                <p className='title'>风控措施</p>
                                <p className="content" dangerouslySetInnerHTML={{
                                    __html: productDetail.RiskControl
                                }}>
                                </p>
                            </>
                            }
                            {productDetail.UseOfFund &&
                            <>
                                <p className='title'>资金用途</p>
                                <p className="content" dangerouslySetInnerHTML={{
                                    __html: productDetail.UseOfFund
                                }}>
                                </p>
                            </>
                            }
                            {productDetail.Payment &&
                            <>
                                <p className='title'>还款来源</p>
                                <p className="content" dangerouslySetInnerHTML={{
                                    __html: productDetail.Payment
                                }}>
                                </p>
                            </>
                            }
                        </div>
                        <div className="mainRight">
                          <div className={'topFile'}>
                            <div className="titleBox">
                              <p className="title">附件信息</p>
                              {/*<div>*/}
                              {/*    <img className='icon' src={require('../../../images/download.png')} alt=""/>*/}
                              {/*    <img className='icon' src={require('../../../images/email.png')} alt=""/>*/}
                              {/*</div>*/}
                            </div>
                            {
                              this.state.productDetail.FileList.map((item, index) => {
                                if (item.FileList.length > 0 && (item.Name === '简版' || item.Name === '推介材料' || (item.Name !== '简版' && item.Name !== '推介材料' && this.state.center.IsCertification)))
                                  return (
                                    <div className="fileList" key={index}>
                                      <p className="title">{item.Name}</p>
                                      {item.FileList.length > 0 &&
                                      <p className='fileName'
                                         onClick={() => {
                                           window.open(config.apiUrl + item.FileList[item.FileList.length - 1].FilePath)
                                         }}
                                      > {item.FileList[item.FileList.length - 1].FileName}</p>}

                                    </div>
                                  )
                              })
                            }
                            {this.state.productDetail.FileList.length===0 &&
                            <div style={{padding:10}}>
                              <Empty></Empty>
                            </div>
                            }
                            {
                              !this.state.center.IsCertification &&
                              <div className={'more'}>了解更多产品信息，请先完成 <a href="/center/my-info">实名认证</a></div>
                            }
                          </div>
                          <div className="bottomFile">
                            <div className="titleBox">
                              <p className="title">投后管理报告</p>
                            </div>
                            <div className="fileList">
                              {
                                this.state.center.IsCertification && this.state.productDetail.InvestReport.map((item,index) => {
                                  return (
                                    <p className='fileName'
                                       key={index}
                                       onClick={() => {
                                         window.open(config.apiUrl + item.FilePath)
                                       }}
                                    > {item.FileName}</p>
                                  )
                                })
                              }
                            </div>
                            {
                              this.state.productDetail.InvestReport.length===0 &&
                                <div style={{padding:10}}>
                                  <Empty></Empty>
                                </div>
                            }
                            {
                              !this.state.center.IsCertification &&
                              <div className={'more'}>了解更多产品信息，请先完成 <a href="/center/my-info">实名认证</a></div>
                            }
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default withRouter(ProductDetail);
