import React, {Component} from 'react';
import './index.less'
import area from '../../../utils/area'
import {Input, DatePicker, Select, Cascader, Radio, Upload, Icon, message, Form, Button} from "antd";
import {Certification, UpdateUserInfo} from '../../../js/http-serve/index'
import moment from "moment";
import {ApplyEmployee} from "../../../js/http-serve";

const {RangePicker} = DatePicker;
const {Option} = Select;


class MyInfo extends Component {


    constructor(props) {
        super(props);
        this.state = {
            forms: {
                Thumbail: '',
                Name: ''
            },
            loading: false,
            isEdit: false,
        }
        this.CredentalType = [
            {title: '居民身份证', value: 1},
            {title: '护照', value: 2},
            {title: '台湾居民来往大陆通行证', value: 4},
            {title: '港澳居民来往大陆通行证', value: 5},
            {title: '其他', value: 6},
            {title: '营业执照', value: 7}
        ]
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (this.props.center.IsCertification === true) {
                    this.changeInfo(values)
                } else {
                    this.submit(values)
                }
            }
        });
    }


    //修改用户信息
    changeInfo = async (value) => {
        try {
            console.log(value)
            let detail = await UpdateUserInfo(value)
            this.setState({
                isEdit: false
            })

            this.props.getInfo()
        } catch (e) {
        }
    }

    //提交表单
    submit = async (values) => {
        try {
            const regCredentialType = /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
            let form1 = {
                RealName: values.RealName,
                CredentialType: values.CredentialType,
                CredentialNumber: values.CredentialNumber,
                CredentialExpiryDateStartTime: '',
                CredentialExpiryDateEndTime: '',
                ApplyEmoloyeeCode: values.ApplyEmoloyeeCode,
            };
            if (form1.CredentialType === 1 && !regCredentialType.test(form1.CredentialNumber)) {
                message.warning('您的身份证信息不合法，请检查后重新输入')
                return
            }
            if (form1.CredentialType === 1) {
                form1.CredentialNumber = form1.CredentialNumber.toUpperCase()
            }
            if (values.CredentialExpiryDate && values.CredentialExpiryDate.length > 0) {
                form1.CredentialExpiryDateStartTime = moment(values.CredentialExpiryDate[0]).format('YYYY-MM-DD')
                form1.CredentialExpiryDateEndTime = moment(values.CredentialExpiryDate[1]).format('YYYY-MM-DD')
            }
            let cer = await Certification(form1)
            if (cer === 1) {
                let apply = await ApplyEmployee(form1)
                this.props.history.replace('/center')
            } else {
                this.props.history.replace('/center')
            }
            // let form2 = {
            //     Gender: 1
            // }
            // form2.Gender = values.Gender ? values.Gender : 1
            // await UpdateGender(form2)
            message.success('认证成功')
        } catch (e) {

        }
    }


    componentDidMount() {
    }

    getCredentialType(type) {
        switch (type) {
            case 1:
                return '居民身份证';
            case 2:
                return '护照';
            case 4:
                return '台湾居民来往大陆通行证';
            case 5:
                return '港澳居民来往大陆通行证';
            case 6:
                return '其他';
            case 7:
                return '营业执照';
            default:
                return ''
        }
    }

    render() {
        let {forms} = this.state
        let {info} = this.props
        const {getFieldDecorator} = this.props.form;
        return (
            <div className="indialInfo">
                <Form onSubmit={this.handleSubmit}>
                    <div className="container">
                        {
                            !this.props.center.IsCertification && <>
                                <p className="title">实名认证</p>
                                <div className="form">
                                    <p className="formTitle">真实姓名</p>
                                    <div className="formCom">
                                        <Form.Item className='inputStyle'>
                                            {getFieldDecorator('RealName', {
                                                rules: [{required: true, message: '请填写真实姓名'}],
                                            })(
                                                <Input style={{width: 230}} placeholder="请填写真实姓名"/>,
                                            )}
                                        </Form.Item>
                                        <p className='desc'>为确保您的账户安全，请输入本人的实名认证信息</p>
                                    </div>
                                </div>
                                <div className="form">
                                    <p className="formTitle">证件类型</p>
                                    <div className="formCom">
                                        <Form.Item className='inputStyle'>
                                            {getFieldDecorator('CredentialType', {
                                                rules: [{required: true, message: '请选择证件类型'}],
                                            })(
                                                <Select
                                                    style={{width: 230}}
                                                    placeholder="请选择证件类型"
                                                >
                                                    {
                                                        this.CredentalType.map((item, index) => {
                                                            return (
                                                                <Option key={index}
                                                                        value={item.value}>{item.title}</Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="form">
                                    <p className="formTitle">证件号码</p>
                                    <div className="formCom">
                                        <Form.Item className='inputStyle'>
                                            {getFieldDecorator('CredentialNumber', {
                                                rules: [{required: true, message: '请填写证件号码'}],
                                            })(
                                                <Input style={{width: 230}} placeholder="请填写证件号码"/>,
                                            )}
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="form">
                                    <p className="formTitle">证件有效期</p>
                                    <div className="formCom">
                                        <Form.Item className='inputStyle'>
                                            {getFieldDecorator('CredentialExpiryDate')(
                                                <RangePicker style={{width: 230}}/>
                                            )}
                                        </Form.Item>
                                    </div>
                                </div>
                                <div className="form">
                                    <p className="formTitle">客户经理工号</p>
                                    <div className="formCom">
                                        <Form.Item className='inputStyle'>
                                            {getFieldDecorator('EmployeeCode')(
                                                <Input style={{width: 230}} placeholder="请填写客户经理工号(选填)"/>,
                                            )}
                                        </Form.Item>
                                        <p className='desc'>若已有客户经理为您服务，请填写客户经理工号</p>
                                    </div>
                                </div>
                                <div className='submitBtn'>
                                    <Button className="submit" type={'primary'} htmlType="submit">提交</Button>
                                </div>
                            </>
                        }
                        {
                            this.props.center.IsCertification &&
                            <>
                                <p className="title">实名认证信息</p>
                                <div className="form">
                                    <p className="formTitle">真实姓名</p>
                                    <span>{info.UserName}</span>
                                </div>
                                <div className="form">
                                    <p className="formTitle">证件类型</p>
                                    <span>{info.UserCredentialTypeDesc}</span>
                                </div>
                                <div className="form">
                                    <p className="formTitle">证件号码</p>
                                    <span>{info.UserCredentialNumber}</span>
                                </div>
                                <div className="form">
                                    <p className="formTitle">证件有效期</p>
                                    {info.UserCredentialExpiryDateStartTime && info.UserCredentialExpiryDateEndTime &&
                                    <span>{moment(info.UserCredentialExpiryDateStartTime).format('YYYY-MM-DD')}至{moment(info.UserCredentialExpiryDateEndTime).format('YYYY-MM-DD')}</span>}
                                    {info.UserCredentialExpiryDateStartTime && !info.UserCredentialExpiryDateEndTime &&
                                    <span>长期</span>}
                                </div>
                                <p className="title mt35">个人信息</p>
                                <div className="form">
                                    <p className="formTitle">手机号</p>
                                    <span>{info.Mobile === null ? '未填写' : info.Mobile}</span>
                                </div>
                                <div className="form">
                                    <p className="formTitle">性别</p>
                                    <div className="formCom">
                                        {this.state.isEdit ? <Form.Item className='inputStyle'>
                                                {getFieldDecorator('UserGender', {
                                                    initialValue: this.props.info.UserGender ? this.props.info.UserGender : null
                                                })(
                                                    <Radio.Group>
                                                        <Radio value={1}>男</Radio>
                                                        <Radio value={2}>女</Radio>
                                                    </Radio.Group>
                                                )}
                                            </Form.Item> :
                                            <span>{info.UserGender === null ? '未填写' : (info.UserGender === 2 ? '女' : '男')}</span>}
                                    </div>
                                </div>

                                <div className="form">
                                    <p className="formTitle">国籍</p>
                                    <div className="formCom">
                                        {this.state.isEdit ? <Form.Item className='inputStyle'>
                                                {getFieldDecorator('UserNationality', {
                                                    initialValue: this.props.info.UserNationality === null ? null : this.props.info.UserNationality
                                                })(
                                                    <Select
                                                        style={{width: 230}}
                                                        placeholder="请选择国籍"
                                                    >
                                                        <Option value={1}>中国</Option>
                                                        <Option value={0}>其他</Option>
                                                    </Select>,
                                                )}
                                            </Form.Item> :
                                            <span>{info.UserNationality === null ? '未填写' : (info.UserNationality === 1 ? '中国' : '其他')}</span>}
                                    </div>
                                </div>
                                <div className="form">
                                    <p className="formTitle">出生日期</p>
                                    <div className="formCom">
                                        {this.state.isEdit ? <Form.Item className='inputStyle'>
                                                {getFieldDecorator('UserBirthday', {
                                                    initialValue: this.props.info.UserBirthday ? moment(this.props.info.UserBirthday) : null
                                                })(
                                                    <DatePicker style={{width: 230}} placeholder='请选择出生日期'/>,
                                                )}
                                            </Form.Item> :
                                            <span>{info.UserBirthday === null ? '未填写' : moment(info.UserBirthday).format('YYYY-MM-DD')}</span>}
                                    </div>
                                </div>
                                <div className="form">
                                    <p className="formTitle">电子邮箱</p>
                                    <div className="formCom">
                                        {this.state.isEdit ? <Form.Item className='inputStyle'>
                                                {getFieldDecorator('Email', {
                                                    initialValue: this.props.info.Email
                                                })(
                                                    <Input style={{width: 230}} placeholder="请填写电子邮箱"/>,
                                                )}
                                            </Form.Item> :
                                            <span>{info.Email === null ? '未填写' : info.Email}</span>}
                                    </div>
                                </div>
                                <div className='submitBtn'>
                                    {this.state.isEdit ?
                                        <Button className="submit" type={'primary'} htmlType="submit">保存</Button>
                                        : <Button className="submit" type={'primary'} htmlType="button"
                                                  onClick={(e) => {
                                                      e.preventDefault()
                                                      this.setState({isEdit: true})
                                                  }}>修改</Button>}

                                </div>
                                {/*<div className="form">*/}
                                {/*    <p className="formTitle">通讯地址</p>*/}
                                {/*    <div className="formCom">*/}
                                {/*        <Form.Item className='inputStyle'>*/}
                                {/*            {getFieldDecorator('Address')(*/}
                                {/*                <Cascader style={{width: 230, marginRight: 15}} placeholder="请选择通讯地址"*/}
                                {/*                          options={area}/>,*/}
                                {/*            )}*/}
                                {/*        </Form.Item>*/}
                                {/*        <Form.Item className='inputStyle'>*/}
                                {/*            {getFieldDecorator('AddressDetail')(*/}
                                {/*                <Input style={{width: 230}} placeholder="请填写详细地址"/>,*/}
                                {/*            )}*/}
                                {/*        </Form.Item>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </>
                        }

                    </div>
                </Form>
            </div>
        );
    }
}

export default Form.create({name: 'register'})(MyInfo);
