import React, { Component } from 'react';
import ClassifyTitle from '../../components/classify-title';
// import {GetNews} from '../../js/http-serve';
import { Player, BigPlayButton } from 'video-react';
import 'video-react/dist/video-react.css';

import './news.less';
import {
    GetArticleFrontList,
    GetPlateDetail,
    GetPlateList,
    GetToken,
    GetVideoFrontList,
    GetWebSiteProductList
} from "../../js/http-serve";
import { api } from "../../js/http-serve/ajax";
import config from '../../js/config'
import { getDay, getMonth } from "../../utils";
import { Row, Col, Pagination, Modal } from 'antd'
import { User } from "../../service/user";

class News extends Component {
    player = null;

    constructor(props) {
        super(props);
        this.state = {
            focusList: [],
            plateList: [],
            selectedPlate: '',
            query: {
                PageSize: 5,
                PageIndex: 1,
                TotalCount: 0
            },
            showVideo: false,
            VideoUrl: '',
            VideoPhoto: '',
            initClass: true
        }
    }

    componentWillMount() {
    }

    async componentDidMount() {
        let plateList = await GetPlateList()
        let idx = plateList.Data.findIndex(el => el.Title === '其他' && el.PlateId === 'f4f99e58-3b93-4da3-2bc4-08d70be7f89f')
        // if (idx) {
        //     plateList.Data.splice(idx, 1)
        // }
        this.getPlateDetail(plateList.Data[0])
        this.setState({
            plateList: plateList.Data,
            selectedPlate: plateList.Data[0].PlateId
        })
        console.log(plateList)
    }

    async getPlateDetail(plate) {
        let { query } = this.state
        this.setState({
            selectedPlate: plate.PlateId
        })
        let detail = await GetPlateDetail({ PlateId: plate.PlateId })
        if (detail.Type === 1) {
            let videoList = await GetVideoFrontList({
                PlateId: plate.PlateId,
                ...query
            });
            this.setState({
                focusList: videoList.Data
            });
            query.TotalCount = videoList.TotalCount
        } else if (detail.Type === 2) {
            let articleList = await GetArticleFrontList({
                PlateId: plate.PlateId,
                ...query
            });
            this.setState({
                focusList: articleList.Data
            });
            query.TotalCount = articleList.TotalCount
        } else if (detail.Type === 3) {
            let videoList = await GetVideoFrontList({
                PlateId: plate.PlateId,
                ...query
            });
            let articleList = await GetArticleFrontList({
                PlateId: plate.PlateId,
                ...query
            });
            let concatList = videoList.Data.concat(articleList.Data);
            this.setState({
                focusList: concatList,
            });
            query.TotalCount = articleList.TotalCount
        }
        this.setState({ query })
    }

    //点击页码回调
    SizeChange = (page, pageSize) => {
        let { query, selectedPlate } = this.state
        query.PageIndex = page
        let data = {
            PlateId: selectedPlate
        };
        this.setState(query, () => {
            document.body.scrollTop = 400;
            document.documentElement.scrollTop = 400;
            this.getPlateDetail(data)
        })
    };

    toDetail = (id) => {
        this.props.history.push('/news/newsDetail/articleDetail/' + id)
    };

    showVideoDetail = (Url, Pic) => {
        this.setState({
            showVideo: true,
            VideoUrl: Url,
            VideoPhoto: Pic
        })
    };

    handleOk = e => {
        this.setState({
            showVideo: false,
        });
    };

    handleCancel = e => {
        this.player.pause();
        this.setState({
            showVideo: false,
        });
    };

    renderNews = (item, index) => {
        return (
            <div key={index + '新闻'}>
                {
                    item.ArticleId ?
                        <Row
                            key={index + item.ArticleId}
                            className={`news-list ${0 === index && this.state.initClass ? 'news-first' : ''}`}
                            onClick={() => this.toDetail(item.ArticleId)}
                            onMouseEnter={() => {
                                if (this.state.initClass) {
                                    this.setState({
                                        initClass: false
                                    }
                                    )
                                }
                            }}
                        >
                            <Col span={18}>
                                <div className={'month-row'}>
                                    <div className={'month-box'}>{getMonth(item.CreateTime)}</div>
                                    <div className={'month-title'}>
                                        <div className={'border'} />
                                        {item.Title}</div>
                                </div>
                                <div className={'day-row'}>
                                    <p className={'day'}>{getDay(item.CreateTime)}</p>
                                    <p className={'news-list-summary'}>
                                        {item.Summary}
                                    </p>
                                </div>
                            </Col>
                            <Col span={6} className={'news-list-img-box'}>
                                <img className={'news-list-img'} src={config.apiUrl + item.Thumbnail} alt="" />
                            </Col>
                        </Row>
                        :
                        <Row key={index + item.VideoId}
                            className={`news-list ${0 === index && this.state.initClass ? 'news-first' : ''}`}
                            onClick={() => this.showVideoDetail(item.Link, item.Photo)}
                            onMouseEnter={() => {
                                if (this.state.initClass) {
                                    this.setState({
                                        initClass: false
                                    }
                                    )
                                }
                            }}
                        >
                            <Col span={18}>
                                <div className={'month-row'}>
                                    <div className={'month-box'}>{getMonth(item.CreateTime)}</div>
                                    <div className={'month-title'}>
                                        <div className={'border'} />
                                        {item.Title}</div>
                                </div>
                                <div className={'day-row'}>
                                    <p className={'day'}>{getDay(item.CreateTime)}</p>
                                    <p className={'news-list-summary'}>
                                        {item.Introduction}
                                    </p>
                                </div>
                            </Col>
                            <Col span={6} className={'news-list-img-box'}>
                                <img className={'news-list-img'} src={config.apiUrl + item.Photo} alt="" />
                            </Col>
                        </Row>
                }
            </div>

        )
    };


    render() {
        const { focusList, plateList, selectedPlate, query, VideoUrl, VideoPhoto } = this.state;
        return (
            <div className="news">
                <div className="banner">
                    <img src={require('../../images/news-bg.png')} alt="" className={'w100'} />
                </div>
                <div className="news-trends">
                    <ClassifyTitle cn="新闻中心" en="NEWS CENTER" className="title" />
                    <div className={'news-type'}>
                        {plateList.map((item, i) => {
                            return <span
                                key={item.PlateId}
                                className={item.PlateId === selectedPlate ? 'active-plate' : ''}
                                onClick={() => {
                                    this.setState({ initClass: true })
                                    this.getPlateDetail(item)
                                }}>{item.Title}</span>
                        })}
                    </div>
                    <div className={'news-box'}>
                        {focusList.map(this.renderNews)}
                    </div>
                    <div className="pagination">
                        <Pagination
                            className={'mr-30'}
                            onChange={(page, pageSize) => this.SizeChange(page, pageSize)}
                            pageSize={query.PageSize}
                            current={query.PageIndex}
                            total={query.TotalCount}
                        />
                    </div>
                </div>
                <Modal
                    title="视频"
                    maskClosable={false}
                    width={1000}
                    footer={null}
                    visible={this.state.showVideo}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <Player
                        ref={(player) => {
                            this.player = player
                        }}
                        width={800}
                        height={500}
                        src={VideoUrl}
                        poster={VideoPhoto}
                    >
                        <BigPlayButton position="center" />
                    </Player>
                </Modal>
            </div>

        );
    }
}

export default News;
