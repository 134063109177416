import React, {Component} from 'react';
import {Upload, message} from 'antd';
import './index.less';
import config from '../../js/config';
import {api} from '../../js/http-serve/ajax'


const props = {
    showUploadList: false,
    name: 'file',
    action: config.apiUrl + '/api/File/UploadFiles',
};

export class UploadFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imageUrls: this.props.imgUrls || [],
        };

    }

    componentWillReceiveProps(next) {
        this.setState({
            imageUrls: next.imgUrls
        });

    }

    delete = (index) => {
        this.props.onChange('delete', index);
    }

    componentDidMount() {
        props.headers = {
            authorization: 'Bearer ' + api.getToken()
        }
    }

    render() {

        const {imageUrls} = this.state;
        return (
            <div className={`upload-file ${this.props.className ? this.props.className : ''}`}>
                <h1>{this.props.title}</h1>
                <p>{this.props.desc}</p>
                <ul>
                    {
                        imageUrls.length > 0 && imageUrls.map((item, i) => {
                            return (
                                <li key={i} className={this.props.canedit ? 'delete' : ''}>
                                    <img src={config.apiUrl + item.Url} className="dynamic"/>
                                    <div className="mask">
                                        <img src={require('../../images/delete.png')}
                                             onClick={this.delete.bind(this, i)}/>
                                    </div>
                                </li>
                            )
                        })

                    }
                    {
                        (imageUrls.length == 0 || this.props.canedit) &&
                        <li>
                            <Upload {...props} disabled={!this.props.canedit}
                                    onChange={(info) => {
                                        if (info.file.status === 'done') {

                                            if (info.file.type.indexOf('image') === -1) {
                                                message.warning('请上传图片')
                                            } else {
                                                this.props.onChange('add', info)
                                            }

                                        } else if (info.file.status === 'error') {

                                        }
                                    }
                                    }>
                                <img src={require('../../images/verical-arrow.png')}/>
                            </Upload>
                        </li>
                    }
                </ul>

            </div>

        );
    }
}


