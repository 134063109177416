import React, {Component} from 'react';
import './index.less';
import {list} from 'postcss';
import {User} from "../../../service/user";
import {api} from "../../../js/http-serve/ajax";
import * as echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/legendScroll'
import {
    GetAssetReport,
    GetMyTradeList
} from "../../../js/http-serve";
import {AssetReport} from "../center.model";
import moment from "moment";
import accounting from 'accounting'
import {Pagination} from "antd";

class MyProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            showNumber: true,
            query: {
                PageSize: 5,
                PageIndex: 1,
                StockStatus: '',
                TotalCount: 0
            },
            assetReport: new AssetReport()
        }
    }

    async componentDidMount() {
        let chartData = [];
        let chart = await GetAssetReport();
        chartData = chart.TotalAssetAllocationResult;
        chartData.map(item => {
          item.name = item.AssetAllocationName;
          item.value = item.Money
        });
        this.getMyTradeList();
        this.setState({
            chart
        });

        let myChart = echarts.init(document.getElementById('mainChart'));

        var option = {
          tooltip : {
            trigger: 'item',
            formatter: "{a} <br/>{b} : {c}万元 ({d}%)"
          },
          legend: {
            type: 'scroll',
            orient: 'vertical',
            right: 'right',
            data: chartData,
          },
          color: ['#f8db49',
            '#74b0f9',
            '#e88181',
            '#f3ba88',
            '#68c5c9',
            '#b1a4f9',
            '#eb75a8',
            '#9eb361',
            '#8f726f',
            '#8e98b2',],
          series : [
            {
              name: '累计资产',
              type: 'pie',
              radius : '55%',
              center: ['50%', '50%'],
              data: chartData,
              itemStyle: {
                emphasis: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };

        myChart.setOption(option);
    }

    toggle = () => {
        this.setState({
            showNumber: !this.state.showNumber
        });
    };
    fitlerList = (i) => {
        this.setState({
            query: {
                ...this.state.query,
                PageIndex: 1,
                StockStatus: i
            },
        }, () => {

            this.getMyTradeList()
        });
    };

    toDetail = (item) => {
        this.props.history.push('/center/my-product/' + item.TradeId);
    };


    //点击页码回调
    SizeChange = (page, pageSize) => {
        let {query} = this.state;
        query.PageIndex = page;
        this.setState(query, () => {
            document.body.scrollTop = 100;
            document.documentElement.scrollTop = 100;
            this.getMyTradeList()
        })
    };

    async getMyTradeList() {
        let {query} = this.state;
        let product = await GetMyTradeList(query);
        query.TotalCount = product.TotalCount;
        this.setState({
            list: product.Data,
            query,
        })
    }

    render() {
        const {list, showNumber} = this.state;
        return (
            <div className="my-product">
                <div className="top">
                    <div className="overview">
                        <div className="f">
                            <div className="t">
                                <p>累计投资金额</p>
                                <p>{showNumber ? this.props.center.TotalInvestMoney : '--'}万元</p>
                            </div>
                            <div className="t">
                                <p>存量金额</p>
                                <p>{showNumber ? this.props.center.StockMoney : '--'}万元</p>
                            </div>
                            <div className="o" onClick={this.toggle}>
                                {
                                    showNumber ? <img src={require('../../../images/concel.png')}/> :
                                        <img src={require('../../../images/eye.png')}/>
                                }
                                <span>点击可{showNumber ? '隐藏' : '显示'}数字</span>
                            </div>
                        </div>
                        <div className="l">
                            <div>
                                <span>昨日收益(元):</span>
                                <span>{showNumber ? accounting.formatNumber(this.props.center.YesterdayIncome, 2) : '--'}</span>
                            </div>
                            <div>
                                <span>累计收益(元):</span>
                                <span>{showNumber ? accounting.formatNumber(this.props.center.TotalIncome, 2) : '--'}</span>
                            </div>
                        </div>
                        <p>注:以上收益数据只供参考，以实际兑付情况为准。</p>
                    </div>
                    <div className="chart-wrap">
                        <span>累计资产(万元)</span>
                        <div className="chart">
                            <div id="mainChart" style={{width: 310,height: 240}}></div>
                        </div>
                    </div>
                </div>
                <div className="pro-bottom">
                    <ul className="type">
                        <li onClick={(e) => {
                            this.fitlerList('')
                        }} className={this.state.query.StockStatus === '' ? 'active' : ''}>全部
                        </li>
                        <li onClick={(e) => {
                            this.fitlerList(1)
                        }} className={this.state.query.StockStatus === 1 ? 'active' : ''}>待存续
                        </li>
                        <li onClick={(e) => {
                            this.fitlerList(2)
                        }} className={this.state.query.StockStatus === 2 ? 'active' : ''}>存续中
                        </li>
                        <li onClick={(e) => {
                            this.fitlerList(3)
                        }} className={this.state.query.StockStatus === 3 ? 'active' : ''}>已到期
                        </li>
                    </ul>
                    <ul className="list">
                        {
                            list.length > 0 ? list.map((item, i) => {
                                    return (
                                        <li key={item.TradeId + item.StockId}>
                                            <h1>{item.ProductName}<span>{item.StockStatusDesc}</span></h1>
                                            <div>
                                                <div>
                                                    <p>{item.OriginalStockMoney}万元</p>
                                                    <p>初始存量金额</p>
                                                </div>
                                                <div>
                                                    <p>{item.ValueDate ? moment(item.ValueDate).format('YYYY-MM-DD') : '--'}</p>
                                                    <p>成立日</p>
                                                </div>
                                                <div>
                                                    <p>{item.MaturityDate ? moment(item.MaturityDate).format('YYYY-MM-DD') : '--'}</p>
                                                    <p>到期日</p>
                                                </div>
                                                <div>
                                                    <button className="btn" onClick={this.toDetail.bind(this, item)}>查看
                                                    </button>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                                :
                                <li style={{textAlign: 'center'}}>
                                    <img src={require('../../../images/no-data.png')}/>
                                </li>
                        }
                    </ul>
                    <div className="pagination">
                        <Pagination
                            className={'mr-30'}
                            onChange={(page, pageSize) => this.SizeChange(page, pageSize)}
                            pageSize={this.state.query.PageSize}
                            current={this.state.query.PageIndex}
                            total={this.state.query.TotalCount}
                        />
                    </div>
                </div>
            </div>


        );
    }
}


export default MyProduct;
