import React, {Component} from 'react';
import {DatePicker, Pagination} from 'antd';
import './index.less';
import {GetAssetReport, GetIspUserManageCenterForApp, GetStatementOfAccountList} from "../../../js/http-serve";
import {Statement} from "../center.model";
import locale from 'antd/lib/date-picker/locale/zh_CN';
import moment from "moment";
import {User} from "../../../service/user";
import {api} from "../../../js/http-serve/ajax";
import * as accounting from "accounting";

const {RangePicker} = DatePicker;

class MyAssets extends Component {
    constructor(props) {
        super(props)
        this.state = {
            statement: new Statement(),
            showNumber: true,
            query: {
                PageSize: 5,
                PageIndex: 1,
                TotalCount: 0,
                Category: '',
                QueryDateStart: '',
                QueryDateEnd: ''
            },

        }
        this.Category = [
            {
                id: '',
                text: '全部'
            },
            {
                id: 1,
                text: '本金'
            },
            {
                id: 4,
                text: '退款'
            },
            {
                id: 5,
                text: '兑付'
            },
            {
                id: 6,
                text: '收益'
            },
        ]


    }

    toggle = () => {
        this.setState({
            showNumber: !this.state.showNumber
        });
    };


    fitlerList = (item) => {
        this.setState({
            query: {
                ...this.state.query,
                PageIndex: 1,
                Category: item
            }
        }, this.getStatementOfAccountList)
    };


    timeChange = (e, f) => {
        this.setState({
            query: {
                ...this.state.query,
                PageIndex: 1,
                QueryDateStart: f[0],
                QueryDateEnd: f[1],
            }
        }, this.getStatementOfAccountList)
    };


    toDetail = (item) => {
        this.props.history.push('/center/my-asset/' + item.Id + '?' + item.Category)

    };


    componentDidMount() {
        this.getStatementOfAccountList()
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.getStatementOfAccountList()
    }


    getStatementOfAccountList = async () => {
        let statement = await GetStatementOfAccountList(this.state.query)
        this.setState({
            statement,
            query: {
                ...this.state.query,
                TotalCount: statement.StatementOfAccountList.TotalCount
            }
        })
    }


    //点击页码回调
    SizeChange = (page, pageSize) => {
        let {query} = this.state
        query.PageIndex = page
        this.setState(query, () => {
            document.body.scrollTop = 100;
            document.documentElement.scrollTop = 100;
            this.getStatementOfAccountList()
        })
    }

    getCategory(val) {
        switch (val) {
            case 1:
                return '本金';
            case 2:
                return '本金';
            case 3:
                return '本金';
            case 4:
                return '退款';
            case 5:
                return '兑付';
            case 6:
                return '收益';
            case 7:
                return "收益";
            default:
                return ''
        }
    }

    render() {
        const {showNumber} = this.state;
        return (
            <div className="my-asset">
                <div className="top">
                    <div className="overview">
                        <div className="f">
                            <div className="t">
                                <p>投资金额</p>
                                <p>{showNumber ? accounting.formatNumber(this.state.statement.TotalExpense, 2) : '--'}元</p>
                            </div>
                            <div className="t">
                                <p>总收入</p>
                                <p>{showNumber ? accounting.formatNumber(this.state.statement.TotalIncome, 2) : '--'}元</p>
                            </div>
                            <div className="o" onClick={this.toggle}>
                                {
                                    showNumber ? <img src={require('../../../images/concel.png')}/> :
                                        <img src={require('../../../images/eye.png')}/>
                                }
                                <span>点击可{showNumber ? '隐藏' : '显示'}数字</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pro-bottom">
                    <ul className="type">
                        {
                            this.Category.map((item, i) => {
                                return (
                                    <li
                                        key={i}
                                        onClick={() => {
                                            this.fitlerList(item.id)
                                        }}
                                        className={this.state.query.Category === item.id ? 'active' : ''}>{item.text}</li>
                                )
                            })
                        }
                    </ul>
                    <div className="time">
                        <span>日期</span>
                        <RangePicker locale={locale} onChange={this.timeChange}/>
                    </div>
                    <ul className="list">
                        {
                            this.state.statement.StatementOfAccountList.Data.length ? this.state.statement.StatementOfAccountList.Data.map((item, i) => {
                                    return (
                                        <li key={item.Id}>
                                            <h1>{item.ProductName}<span>{this.getCategory(item.Category)}</span></h1>
                                            <div>
                                                <div>
                                                    <p>{item.Money}{(item.Category === 6 || item.Category === 7) ? '元' : '万元'}</p>
                                                    <p>金额</p>
                                                </div>
                                                <div>
                                                    <p>{moment(item.Date).format('YYYY-MM-DD')}</p>
                                                    <p>交易日期</p>
                                                </div>
                                                <div>
                                                    <button className="btn" onClick={this.toDetail.bind(this, item)}>查看
                                                    </button>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                                :
                                <li style={{textAlign: 'center'}}>
                                    <img src={require('../../../images/no-data.png')}/>
                                </li>
                        }
                    </ul>
                    <div className="pagination">
                        <Pagination
                            className={'mr-30'}
                            onChange={(page, pageSize) => this.SizeChange(page, pageSize)}
                            pageSize={this.state.query.PageSize}
                            current={this.state.query.PageIndex}
                            total={this.state.query.TotalCount}
                        />
                    </div>
                </div>
            </div>


        );
    }
}


export default MyAssets;
