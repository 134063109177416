import React, {Component} from 'react';
import './index.less';
import {Modal} from 'antd';
import {GetRpqQuestionList, GetRpqResult, GetAssessmentResultInfo} from '../../../js/http-serve';

class MyRpq extends Component {
    state = {
        questions: [],
        rpqFinished: false,
        result: {},
        readyToSubmit: false
    };

    componentDidMount() {

    }

    componentWillReceiveProps(next) {
        //如果做过了直接获取结果 否则直接做题目
        if (next.center.AssessmentResultInfoId) {
            this.setState({
                rpqFinished: true
            });
            this.getAssessmentResultInfo(next);
        } else {
            this.getRpqQuestionList();
        }
    }

    //GetRpqQuestionList 获取题目
    getRpqQuestionList = async () => {

        let data = await GetRpqQuestionList({});
        this.setState({
            questions: data
        });

    };
    //GetAssessmentResultInfo 获取结果
    getAssessmentResultInfo = async (props) => {

        let data = await GetAssessmentResultInfo({AssessmentResultInfoId: props.center.AssessmentResultInfoId});
        data.CreateTime = data.CreateTime && data.CreateTime.split('T')[0]
        this.setState({
            result: data
        });

    };
    //做题
    select = (o, item) => {
        if (o.IsChoose) return;
        item.OptContentScores.forEach(element => {
            element.IsChoose = false;
        });
        o.IsChoose = true;
        this.forceUpdate();

    };
    //提交 GetRpqResult 保存题目
    submit = async () => {
        //校验题目是不是全部做完
        let ok = this.state.questions.every((item) => {
            return item.isDid = !item.OptContentScores.every((o) => {
                return !o.IsChoose
            })
        });
        if (ok) {
            let data = await GetRpqResult(this.state.questions);
            this.setState({
                result: data,
                rpqFinished: true,
                readyToSubmit: false
            });

        } else {
            Modal.error({
                title: '提示',
                content: '您有题目没有做完，请做完再提交!'
            });
            this.setState({
              readyToSubmit: true
            })
        }

    };

    //
    reEvaluate = () => {
        this.getRpqQuestionList();
        this.setState({
            rpqFinished: false
        });

    };


    render() {

        let {questions, rpqFinished, result, readyToSubmit} = this.state;
        questions.forEach((item) => {
            item.isDid = !item.OptContentScores.every((o) => {
                return !o.IsChoose
            })
        });


        return (
            <div className="my-rpq">
                <h1>风险测评</h1>
                {
                    rpqFinished ? (<div className="rpq-result">
                            <p><span>测评结果：</span><span>{result.LevelName}</span></p>
                            {/*<p><span>测评时间：</span><span>{result.CreateTime}</span></p>*/}
                            <p><span>投资建议：</span><span>{result.RiskExplanation}</span></p>
                            <div style={{textAlign: 'center'}}>
                                <button className="btn" onClick={this.reEvaluate}>重新测评</button>
                            </div>
                        </div>) :
                        (<div>
                                <p style={{fontSize: '16px'}}>请结合自身情况选择</p>
                                <ul>
                                    {
                                        questions.map((item, index) => {
                                            return (
                                                <li className="ques" key={index}>
                                                    <p>
                                                        {index + 1}:&nbsp;&nbsp;{item.QueContent}
                                                        {
                                                            readyToSubmit ?
                                                            item.isDid ? <span className="did">已做</span> : <span className="undid"><span className={'close'}>X</span>未做</span>
                                                              :
                                                            item.isDid ? <span className="did">已做</span> : null
                                                        }

                                                    </p>
                                                    <div>
                                                        {
                                                            item.OptContentScores.map((o, i) => {
                                                                return (
                                                                    <div
                                                                        key={i}
                                                                        onClick={this.select.bind(this, o, item)}>
                                                                        <span
                                                                            className={`dot ${o.IsChoose ? 'active' : ''}`}/>
                                                                        <span>{o.OptContent}</span>
                                                                    </div>

                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                <div className="btn-wrap">
                                    <button className="btn" onClick={this.submit}>完成</button>
                                </div>
                            </div>
                        )
                }

            </div>)
    }
}


export default MyRpq;
