export class ProductDetailModel {
    Id
    Type
    SortId
    Slogan
    Brief
    HighLight
    RiskControl
    UseOfFund
    Payment
    ProductName
    ProductId
    IssueQuota
    IssueRestMoney
    IssueCurrentMoney
    IssuePendingMoney
    HasIssueQuota
    InvestMin
    IncreasingAmount
    InvestmentHorizon
    ProductTypeDesc
    FileList
    StageBar
    Status
    WebSiteSortId
    MinProfitRate
    MaxProfitRate
    IncomeModels
    InvestReport


    constructor() {
        this.Id = '';
        this.Type = '';
        this.SortId = '';
        this.Slogan = '';
        this.Brief = '';
        this.HighLight = '';
        this.RiskControl = '';
        this.UseOfFund = '';
        this.Payment = '';
        this.ProductName = '';
        this.ProductId = '';
        this.IssueQuota = '';
        this.IssueRestMoney = '';
        this.IssueCurrentMoney = '';
        this.IssuePendingMoney = '';
        this.HasIssueQuota = '';
        this.InvestMin = '';
        this.IncreasingAmount = '';
        this.InvestmentHorizon = '';
        this.ProductTypeDesc = [];
        this.FileList = [];
        this.StageBar = [];
        this.Status = 0;
        this.WebSiteSortId = 0;
        this.MaxProfitRate = ''
        this.MinProfitRate = ''
        this.IncomeModels = []
        this.InvestReport = []
    }
}


