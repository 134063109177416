import React, { Component } from 'react';
import './index.less';


  class DisplayCards extends Component {
    constructor(props){
      super(props);
    }
    componentDidUpdate(){
      let WrapDom = document.querySelector('.children-wrap')
      let children =  Array.from(WrapDom.children);
      if(children.length < 3){
        return ;
      }
      let length = children.length;
      let child = children[0];
      let w = child.offsetWidth;
      let margin = 40;
      WrapDom.style.width = w * length + margin * length + 'px';
      //初始化默认第二个为中间的
      let middle = 1;
      let translateM = 0;
      children[middle].classList.add('active');
    children.forEach((dom,i)=>{
        dom.addEventListener('click',function(){
           if(middle === i) return;
           if(i == 0) return;
           if(i == children.length-1) return;
           children[middle].classList.remove('active');
           //点击右边的
           if(i > middle){
             translateM -=420
           }
            //点击左边的
            if(i < middle){
              translateM +=420
            }
            middle = i;
            children[middle].classList.add('active');
            WrapDom.style.transform = 'translateX(' + translateM + 'px)';
        },false);
      })
    }

    
    render() {
      return (
      <div className={`display-cards ${this.props.className ? this.props.className : ''}`} >
        <div className="children-wrap">
             {this.props.children}
        </div>
      </div>
     
      );
    }
  }

  export default DisplayCards;