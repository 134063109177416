import React, {Component} from 'react';
import './asset-Detail.less';
import {
    GetAdditionalDetail, GetAssignmentDetail,
    GetIncomeDetail,
    GetMyTradeDetail,
    GetPayDetail, GetTradeMaterial,
    GetTradeRedeemDetail
} from "../../../js/http-serve";
import {User} from "../../../service/user";
import {api} from "../../../js/http-serve/ajax";
import moment from "moment";
import * as accounting from "accounting";
import {getFrequency, getIsFloat, period} from "../../../utils";
import config from '../../../js/config'

class AssetDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detail: {},
            material: []
        };
        this.Id = props.match.params.id
        this.Category = props.location.search.split('?')[1]
    }

    componentDidMount() {
        let cate = parseInt(this.Category)
        if (cate === 1) {
            this.getPayDetail()
        } else if (cate === 2) {
            this.getAdditionalDetail()
        } else if (cate === 3) {
            this.getPayDetail()
        } else if (cate === 4) {
            this.getRefundDetail()
        } else if (cate === 5) {
            this.getTradeRedeemDetail()
        } else if (cate === 6 || cate === 7) {
            this.getIncomeDetail()
        } else if (cate === 8 || cate === 9) {
            this.getAssignmentDetail()
        }
    }

    getPayDetail = async () => {
        let detail = await GetPayDetail({
            Id: this.Id,
            Category: this.Category
        })
        this.setState({
            detail
        })
        console.log(detail)
        this.getTradeMaterial(detail.TradeId)
    }

    getAdditionalDetail = async () => {
        let detail = await GetAdditionalDetail({
            Id: this.Id,
            Category: this.Category
        })
        this.setState({
            detail
        })
        this.getTradeMaterial(detail.TradeId)
    }
    getTradeRedeemDetail = async () => {
        let detail = await GetTradeRedeemDetail({
            Id: this.Id,
            Category: this.Category
        })
        this.setState({
            detail
        })
        this.getTradeMaterial(detail.TradeId)
    }
    getRefundDetail = async () => {
        let detail = await GetAdditionalDetail({
            Id: this.Id,
            Category: this.Category
        })
        this.setState({
            detail
        })
        this.getTradeMaterial(detail.TradeId)
    }

    getIncomeDetail = async () => {
        let detail = await GetIncomeDetail({
            Id: this.Id,
            Category: this.Category
        })
        this.setState({
            detail
        })
        this.getTradeMaterial(detail.TradeId)
    }

    getAssignmentDetail = async () => {
        let detail = await GetAssignmentDetail({
            Id: this.Id,
            Category: this.Category
        })
        this.setState({
            detail
        })
        this.getTradeMaterial(detail.TradeId)
    }

    getTradeMaterial = async (tradId) => {
        let material = await GetTradeMaterial({TradeId: tradId})
        this.setState({
            material
        })
    }

    renderInfoBlock() {
      let {detail} = this.state
      let TitleList = []
      let ValueList = []
      switch (parseInt(this.Category)) {
        case 1:
          //报单
          TitleList[0] = '交易金额';
          ValueList[0] = `${accounting.formatNumber(detail.Money,2)}万元`;
          TitleList[1] = '到账日期';
          ValueList[1] = moment(detail.ArrivalDate).format('YYYY-MM-DD');
          TitleList[2] = '付款方式';
          ValueList[2] = detail.PayMethodDesc;
          break;
        case 2://追加
          TitleList[0] = '交易金额';
          ValueList[0] = `${accounting.formatNumber(detail.Money,2)}万元`;
          TitleList[1] = '到账日期';
          ValueList[1] = moment(detail.ArrivalDate).format('YYYY-MM-DD');
          TitleList[2] = '存量金额';
          ValueList[2] = `${accounting.formatNumber(detail.BeforeMoney,2)}万元`;
          TitleList[3] = '最近交易日期';
          ValueList[3] = moment(detail.RecentTransDate).format('YYYY-MM-DD');
          break;
        case 3:
          //申购
          TitleList[0] = '交易金额';
          ValueList[0] = `${accounting.formatNumber(detail.Money,2)}万元`;
          TitleList[1] = '到账日期';
          ValueList[1] = moment(detail.ArrivalDate).format('YYYY-MM-DD');
          TitleList[2] = '付款方式';
          ValueList[2] = detail.PayMethodDesc;
          break;
        case 4://退款
          TitleList[0] = '交易金额';
          ValueList[0] = `${accounting.formatNumber(detail.Money,2)}万元`;
          TitleList[1] = '提交时间';
          ValueList[1] = moment(detail.RefundDate).format('YYYY-MM-DD');
          TitleList[2] = '存量金额';
          ValueList[2] = `${accounting.formatNumber(detail.BeforeMoney,2)}万元`;
          TitleList[3] = '最近交易日期';
          ValueList[3] = moment(detail.RecentTransDate).format('YYYY-MM-DD');
          break;

        case 5://赎回
          if (!detail.IsTransShare) {
            TitleList[0] = '交易金额';
            ValueList[0] = `${accounting.formatNumber(detail.Money,2)}万元`;
            TitleList[1] = '赎回时间';
            ValueList[1] = moment(detail.RedeemDate).format('YYYY-MM-DD');
            TitleList[2] = '存量金额';
            ValueList[2] = detail.StockMoney ?accounting.formatNumber(detail.StockMoney, 2): '0.00'+'万元';
            TitleList[3] = '成立日期';
            ValueList[3] = moment(detail.ValueDate).format('YYYY-MM-DD')
          } else {
            TitleList[0] = '赎回时间';
            ValueList[0] = moment(detail.RedeemDate).format('YYYY-MM-DD');
            TitleList[1] = '赎回份额';
            ValueList[1] = `${accounting.formatNumber(detail.Money, 4)}份`;
            TitleList[2] = '存量金额';
            ValueList[2] = `${accounting.formatNumber(detail.StockMoney,2)}万元`;
            TitleList[3] = '存量份额';
            ValueList[3] = `${accounting.formatMoney(detail.BeforeMoney, '', 4)}份`;
            TitleList[4] = '成立日期';
            ValueList[4] = moment(detail.ValueDate).format('YYYY-MM-DD')
          }
          break;
        case 6://收益 利息
          TitleList[0] = '交易金额';
          ValueList[0] = `${accounting.formatNumber(detail.Money, 2)}元`;
          TitleList[1] = '兑付时间';
          ValueList[1] = moment(detail.Date).format('YYYY-MM-DD');
          TitleList[2] = '计息周期（不含到期日）';
          ValueList[2] = `${moment(detail.StartDate).format('YYYY-MM-DD')}到${moment(detail.EndDate).format('YYYY-MM-DD')}`;
          TitleList[3] = '计息时长';
          ValueList[3] = detail.TimeLength ? detail.TimeLength + '天' : '';
          TitleList[4] = '收益率';
          ValueList[4] = (accounting.formatNumber(detail.ProfitRate, 2) + '%') || '';
          TitleList[5] = '存量金额';
          ValueList[5] = accounting.formatNumber(detail.StockMoney, 2) + '万元';
          break;
        case 7://收益 贴息
          TitleList[0] = '交易金额';
          ValueList[0] = `${accounting.formatNumber(detail.Money, 2)}元`;
          TitleList[1] = '兑付时间';
          ValueList[1] = moment(detail.Date).format('YYYY-MM-DD');
          TitleList[2] = '计息周期（不含到期日）';
          ValueList[2] = `${moment(detail.StartDate).format('YYYY-MM-DD')}到${moment(detail.EndDate).format('YYYY-MM-DD')}`;
          TitleList[3] = '计息时长';
          ValueList[3] = detail.TimeLength ? detail.TimeLength + '天' : '';
          TitleList[4] = '收益率';
          ValueList[4] = (accounting.formatNumber(detail.ProfitRate, 2) + '%') || '';
          TitleList[5] = '存量金额';
          ValueList[5] = accounting.formatNumber(detail.StockMoney, 2) + '万元';
          break;
        case 8://转让
          TitleList[0] = '交易金额';
          ValueList[0] = `${accounting.formatNumber(detail.StockAsset,2)}万元`;
          TitleList[1] = '受让人';
          ValueList[1] = detail.CustomerName;
          TitleList[2] = '生效日期';
          ValueList[2] = moment(detail.EffectiveDate).format('YYYY-MM-DD');
          TitleList[3] = '初始存量金额';
          ValueList[3] = `${accounting.formatNumber(detail.StockAsset,2)}万元`;
          TitleList[4] = '现存量金额';
          ValueList[4] = detail.OriginalStockAsset ?accounting.formatNumber(detail.OriginalStockAsset, 2): '0.00'+'万元';
          TitleList[5] = '产品成立日期';
          ValueList[5] = moment(detail.ValueDate).format('YYYY-MM-DD');
          TitleList[6] = '产品到期日期（不含当天）';
          ValueList[6] = moment(detail.MaturityDate).format('YYYY-MM-DD');
          break;
        case 9://受让
          TitleList[0] = '交易金额';
          ValueList[0] = `${accounting.formatNumber(detail.StockAsset,2)}万元`;
          TitleList[1] = '转让人';
          ValueList[1] = detail.CustomerName;
          TitleList[2] = '生效日期';
          ValueList[2] = moment(detail.EffectiveDate).format('YYYY-MM-DD');
          TitleList[3] = '初始存量金额';
          ValueList[3] = `${accounting.formatNumber(detail.OriginalStockAsset,2)}万元`;
          TitleList[4] = '现存量金额';
          ValueList[4] = `${accounting.formatNumber(detail.StockAsset,2)}万元`;
          TitleList[5] = '产品成立日期';
          ValueList[5] = moment(detail.ValueDate).format('YYYY-MM-DD');
          TitleList[6] = '产品到期日期（不含当天）';
          ValueList[6] = moment(detail.MaturityDate).format('YYYY-MM-DD');
          break;
        default :
          break
      }
      TitleList = TitleList.concat(['户名', '银行卡号', '开户行']);
      ValueList = ValueList.concat([detail.BankAttribution, detail.BankAccount, detail.BankOfDeposit]);
      return (
        TitleList.map((item,index) => {
          return (
            <li key={index}>
              <span className="txt">{item}</span>
              <span
                className="val">{ValueList[index]}</span>
            </li>
          )
        })
      )
    }

    renderAttachedFile() {
      let {detail, material} = this.state
      let attachFileArray = []
      if(detail.EvidenceScannerList && detail.EvidenceScannerList.length > 0) {
        attachFileArray.push({type:1,files: detail.EvidenceScannerList})
      }
      if(detail.MaterialsList && detail.MaterialsList.length > 0) {
        attachFileArray.push({type:2,files: detail.MaterialsList})
      }
      if(detail.WarningDocumentList && detail.WarningDocumentList.length > 0) {
        attachFileArray.push({type:3,files: detail.WarningDocumentList})
      }
      if(material && material.length > 0) {
        attachFileArray.push({type:4,files: material})
      }
      if(attachFileArray.length === 0) return
      return (
        <div>
          {
            attachFileArray.map((item,index) => {
              return (
                <div key={index}>
                  <p className={'auth'}>
                    {item.type === 1 && '凭证'}
                    {item.type === 2 && '材料'}
                    {item.type === 3 && '警示文档'}
                    {item.type === 4 && '成单文件'}
                  </p>
                  {
                    item.files.map((el,i) => {
                      return (
                        <div
                          key={i+ 'files'}
                          className={'fileName'}
                          onClick={() => {
                            window.open(config.apiUrl + el.Url)
                          }}>{el.Name}</div>
                      )
                    })
                  }
                </div>
              )
            })
          }

        </div>
      )
    }


    componentWillMount() {
    }


    render() {

        return (
            <div className="asset-detail">
                <>
                    <h1>{this.state.detail.ProductName}</h1>
                    <ul className="ele">
                      {
                        this.renderInfoBlock()
                      }
                    </ul>
                    {
                        this.state.detail.IncomeModel &&
                        <>
                            <div className="t-t">
                                <span>{this.state.detail.IncomeModel.TypeId}类</span>
                                <span>{this.state.detail.IncomeModel.StartAmount}万元以上</span>
                            </div>
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>阶段</th>
                                    <th>认购期限</th>
                                    <th>付息频次</th>
                                    <th>是否浮动</th>
                                    <th>收益率</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.detail.IncomeModel.IncomeModelPhases.map((item, i) => {
                                        return (
                                            <tr key={item.IncomeModelId}>
                                                <td>{item.PhasesId}</td>
                                                <td>{period(item.Period, item.PeriodUnit)}</td>
                                                <td>{getFrequency(item.Frequency)}</td>
                                                <td>{getIsFloat(item.IsFloat)}</td>
                                                <td className="profit">{item.StartIncome}</td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </>
                    }
                    {this.state.detail.EvidenceScannerList
                    && this.state.detail.EvidenceScannerList.length > 0
                    && <div>
                        {this.renderAttachedFile()}
                    </div>
                    }
                </>
            </div>

        );
    }
}


export default AssetDetail;
