import React, { Component } from 'react';
import { Carousel, Progress, Tooltip } from 'antd';
import './home.less';
import ClassifyTitle from '../../components/classify-title';
import DisplayCards from '../../components/display-cards';
import { GetToken, GetWebSiteProductList } from '../../js/http-serve';
import { api } from '../../js/http-serve/ajax'
import { formatMaxProfitRate } from "../../utils";
import { User } from "../../service/user";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedProducts: [],
            hotProducts: []
        };
        this.queryHot = {
            PageSize: 9999,
            PageIndex: 1,
            TotalCount: 0,
            Type: 1
        };
        this.querySelected = {
            PageSize: 9999,
            PageIndex: 1,
            TotalCount: 0,
            Type: 2
        };
        this.linkList = [
            // {
            //     url: require('../../images/1.png'),
            //     alt: '上海银行',
            //     link: 'http://www.bosc.cn'
            // },
            // {
            //     url: require('../../images/2.png'),
            //     alt: '恒丰银行',
            //     link: 'http://www.hfbank.com.cn'
            // },
            // {
            //     url: require('../../images/3.png'),
            //     alt: '国信证券',
            //     link: 'http://www.guosen.com.cn'
            // },
            // {
            //     url: require('../../images/4.png'),
            //     alt: '华创证券',
            //     link: 'http://www.hczq.com'
            // },
            {
                url: require('../../images/5.png'),
                alt: '国融证券',
                link: 'https://www.grzq.com'
            },
            {
                url: require('../../images/7.png'),
                alt: '财通资产',
                link: 'http://www.caitongam.com'
            },
            {
                mark: true,
                url: require('../../images/6.png'),
                alt: '东方财富证券',
                link: 'http://www.xzsec.com/'
            },
            {
                url: require('../../images/8.png'),
                alt: '恒泰证券',
                link: 'https://www.cnht.com.cn'
            },
            {
                url: require('../../images/9.png'),
                alt: '嘉实资本',
                link: 'http://www.bosc.cn'
            },
            {
                url: require('../../images/10.png'),
                alt: '南湖金交所',
                link: 'https://www.nhifae.com'
            },
            {
                url: require('../../images/11.png'),
                alt: '侨金所',
                link: 'https://www.gocfae.com'
            },
            {
                url: require('../../images/12.png'),
                alt: '普惠金交所',
                link: 'https://baike.baidu.com/item/普惠金融交易中心（大连）有限公司'
            },
            {
                url: require('../../images/13.png'),
                alt: '厦金中心',
                link: 'https://www.xfinex.com'
            },
            {
                url: require('../../images/14.png'),
                alt: '东金中心',
                link: 'https://www.neafex.com'
            },
            {
                url: require('../../images/15.png'),
                alt: '无锡交易所',
                link: 'http://www.wxfae.com'
            },
            {
                url: require('../../images/16.png'),
                alt: '亚租所',
                link: 'https://www.laecap.com'
            },


        ]
    }

    async componentDidMount() {

        let hot = await GetWebSiteProductList(this.queryHot);
        let selected = await GetWebSiteProductList(this.querySelected);
        this.setState({
            hotProducts: hot.Data.length > 0 ? hot.Data : [],
            selectedProducts: selected.Data.length > 0 ? selected.Data : []
        })
    }

    toDetail = (id) => {
        if (User.isLogin()) {
            this.props.history.push('/product/productDetail/' + id);
        } else {
            this.props.history.push('/login')
        }
    };

    render() {
        const { selectedProducts, hotProducts } = this.state;
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            autoplay: true,
            speed: 1000,
            autoplaySpeed: 3000,
            cssEase: "linear",
            arrows: false,
        };
        return (
            <div className="home">
                <Slider className={'dots'} {...settings}>
                    <div className="carousel" onClick={() => {
                        this.props.history.push('/company');
                    }}>
                        <img src={require('../../images/banner1.png')} alt="carousel" />
                    </div>
                    <div className="carousel" onClick={() => {
                        this.props.history.push('/product');
                    }}>
                        <img src={require('../../images/banner2.png')} alt="carousel" />
                    </div>
                    <div className="carousel" onClick={() => {
                        this.props.history.push('/news');
                    }}>
                        <img src={require('../../images/banner3.png')} alt="carousel" />
                    </div>
                </Slider >
                {/*<Slider className={'dots'} {...settings}>*/}

                {/*  <div className="carousel" onClick={() => {*/}
                {/*    this.props.history.push('/product');*/}
                {/*  }}>*/}
                {/*    <img src={require('../../images/banner2.png')} alt="carousel"/>*/}
                {/*  </div>*/}
                {/*  <div className="carousel" onClick={() => {*/}
                {/*    this.props.history.push('/news');*/}
                {/*  }}>*/}
                {/*    <img src={require('../../images/banner3.png')} alt="carousel"/>*/}
                {/*  </div>*/}
                {/*</Slider>*/}
                {
                    User.isAuthentication() ? <div className="hot-products" style={{ marginBottom: 40, marginTop: 80 }}>
                        <ClassifyTitle cn="热门产品" en="HOT PRODUCTS" className="title" />
                        <div style={{ width: '100%', height: 630 }}>
                            <DisplayCards>
                                {
                                    hotProducts.map((item, i) => {
                                        return (<div className="product-item" key={i}>
                                            <div className="pro-title">
                                                <div>
                                                    <img src={require('../../images/structrue.png')} alt="" />
                                                </div>
                                                <span> {item.ProductName}</span>
                                            </div>
                                            <div className="content">
                                                {
                                                    item.IsFloat ?
                                                        <p className="profit-rate">浮动</p>
                                                        :
                                                        <p className="profit-rate">{formatMaxProfitRate(item.MinProfitRate, item.MaxProfitRate)}</p>
                                                }
                                                <p className="profit-type">业绩比较基准</p>
                                                <Tooltip
                                                    className={'mt-20'}
                                                    title={'募集进度' + ((item.IssueCurrentMoney / item.IssueQuota) * 100).toFixed(2) + '%'}>
                                                    <Progress percent={(item.IssueCurrentMoney / item.IssueQuota) * 100}
                                                        size="small" showInfo={false}
                                                        strokeColor="#EDB35B" />
                                                </Tooltip>
                                                <div className="invest-wrap">
                                                    <div className="invest">
                                                        <span>起投金额</span>
                                                        <span>{item.InvestMin}万元</span>
                                                    </div>
                                                    <div className="invest">
                                                        <span>投资期限</span>
                                                        <span>{item.InvestmentHorizon}</span>
                                                    </div>
                                                </div>
                                                <a className="btn" onClick={() => {
                                                    this.toDetail(item.ProductId)
                                                }}>查看详情</a>
                                            </div>
                                        </div>)
                                    })
                                }
                            </DisplayCards>
                        </div>
                    </div> : null
                }
                {
                    User.isAuthentication() ?
                        <div className="selected-products" style={{ marginTop: 30 }}>
                            <ClassifyTitle cn="精选产品" en="SELECTED PRODUCTS" className="title" />
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{ width: '40%' }}>产品名称</th>
                                        <th style={{ width: '15%' }}>业绩比较基准</th>
                                        <th style={{ width: '11%' }}>起投金额</th>
                                        <th style={{ width: '11%' }}>投资期限</th>
                                        <th>募集进度</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        selectedProducts.map((item, index) => {
                                            return (
                                                <tr className={`text-center ${index % 2 === 0 ? '' : 'odd'}`} key={index}>
                                                    <td className='productName'><a className="seemore"
                                                        onClick={() => this.toDetail(item.ProductId)}>{item.ProductName}</a>
                                                    </td>
                                                    <td className="baseline">
                                                        {/*<span>100%.</span>*/}
                                                        <span>{formatMaxProfitRate(item.MinProfitRate, item.MaxProfitRate)}</span>
                                                    </td>
                                                    <td><span>{item.InvestMin}万元</span></td>
                                                    <td><span>{item.InvestmentHorizon}</span></td>
                                                    <td style={{ paddingRight: 20 }}>
                                                        <Tooltip
                                                            title={'募集进度' + ((item.IssueCurrentMoney / item.IssueQuota) * 100).toFixed(2) + '%'}>
                                                            <Progress percent={+(item.IssueCurrentMoney / item.IssueQuota) * 100}
                                                                size="small" showInfo={false}
                                                                strokeColor="#EDB35B" />
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table >

                        </div > : null
                }
                <div className="partners">
                    <ClassifyTitle cn="合作伙伴" en="PARTNERS" className="title" />
                    <div className={'link-box'}>
                        {this.linkList.map((el, index) => {
                            if (el.mark) {
                                return (
                                    <a
                                        target={'_blank'}
                                        className={'link-item'}
                                        href={el.link} key={el.url}>
                                        <img
                                            style={{
                                                width: '270px',
                                                height: '45px'
                                            }}
                                            src={el.url} alt={el.alt} />
                                    </a>
                                )
                            }
                            return (
                                <a
                                    target={'_blank'}
                                    className={'link-item'}
                                    href={el.link} key={el.url}>
                                    <img src={el.url} alt={el.alt} />
                                </a>
                            )
                        })}
                    </div>
                </div >

            </div >

        );
    }
}

export default Home;
