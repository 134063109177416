import React, {Component} from 'react';
import './index.less'
import {Input, Form, Button, message} from "antd";
import {SendIdentifyCode, ResetPassword, LoginUser} from "../../../js/http-serve";
import {User} from "../../../service/user";
import {api} from "../../../js/http-serve/ajax";



class SafePassword extends Component {


  constructor(props) {
    super(props);
    this.state = {
      isSend: false,
      isSending: false,
      time: 0,
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.submit(values)
      }
    });
  }

  submit = async (values) => {
    try {
      if (!(/^1[23456789]\d{9}$/.test(values.Mobile))) {
        message.error('请输入正确的手机号！');
        return
      }
      if (!values.NewPassword) {
        message.error('请输入新密码！');
        return
      }
      if (!values.NewPasswordSec) {
        message.error('请输入新密码！');
        return
      }
      if (values.NewPasswordSec !== values.NewPassword) {
        message.error('两次密码不一致！');
        return
      }
      if (!values.IdentifyingCode) {
        message.error('请输入验证码！');
        return
      }
      let data = {
        Mobile: values.Mobile,
        NewPassword: values.NewPassword,
        IdentifyingCode: values.IdentifyingCode,
      }
      let user = await ResetPassword(data)
      if(user) {
        User.setUser(user)
        api.setToken(user.Token)
        message.success('修改成功！');
      }
      this.props.history.replace('/center')
    } catch (e) {
      //message.error('服务正忙！');
    }
  }

  //获取验证码
  SendIdentifyCode = async () => {
    let {form} = this.props
    if (!form.getFieldValue('Mobile')) {
      message.info('请先填写手机号！')
      return
    }
    let data = {
      Mobile: form.getFieldValue('Mobile')
    }
    await SendIdentifyCode(data)
    message.success('验证码发送中！')
    this.setState({
      isSend: true,
      isSending: false,
      time: 60
    })
    this.coolDown()
  }
  //验证码倒计时
  coolDown = () => {
    this.timer = setTimeout(() => {
      if (this.state.time <= 0) {
        clearTimeout(this.timer);
        this.setState({
          isSend: false
        });
        return;
      }
      this.setState({
        time: --this.state.time
      });
      this.coolDown();
    }, 1000)
  };


  componentDidMount() {
  }

  render() {
    let {isSend, isSending,time} = this.state
    const {getFieldDecorator} = this.props.form;
    return (
      <div className="editPassword">
        <Form onSubmit={this.handleSubmit}>
          <div className="container">
            <p className="title">修改密码</p>
            <div className={'flex mt20'}>
              <span className={'desc'}>手机号：</span>
              <Form.Item className={'form-item flex6'}>
                {getFieldDecorator('Mobile', {
                  rules: [{required: true, message: '请输入手机号'}],
                })(
                  <Input
                    className={'form-item-input'}
                    placeholder="请输入手机号"
                  />,
                )}
              </Form.Item>
            </div>
            <div className="flex">
              <span className={'desc'}>新密码：</span>
              <Form.Item className={'form-item flex6'}>
                {getFieldDecorator('NewPassword', {
                  rules: [{required: true, message: '请输入密码'}],
                })(
                  <Input
                    className={'form-item-input'}
                    type="password"
                    placeholder="请输入密码"
                  />,
                )}
              </Form.Item>
            </div>
            <div className="flex">
              <span className={'desc'}>确认密码：</span>
              <Form.Item className={'form-item flex6'}>
                {getFieldDecorator('NewPasswordSec', {
                  rules: [{required: true, message: '请输入确认密码'}],
                })(
                  <Input
                    className={'form-item-input'}
                    type="password"
                    placeholder="请输入确认密码"
                  />,
                )}
              </Form.Item>
            </div>
            <div className="flex">
              <span className={'desc'}>验证码：</span>
              <div className='form-item flex6'>
                <Form.Item className='form-item'>
                  {getFieldDecorator('IdentifyingCode', {
                    rules: [{required: true, message: '请输入验证码'}],
                  })(
                    <Input
                      className={'form-item-input'}
                      placeholder="请输入验证码"
                    />,
                  )}
                </Form.Item>
                {
                  isSend ?
                    <div className='code'>已发送({time})</div>
                    :
                    (
                      isSending ?
                        <div className='code'>发送中</div>
                        :
                        <div className='code'
                             onClick={() => this.SendIdentifyCode()}>获取验证码</div>
                    )
                }

              </div>
            </div>
            <div className='submitBtn'>
              <Button className="submit" type={'primary'} htmlType="submit">提交</Button>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

export default Form.create({name: 'register'})(SafePassword);