import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import './product.less';
import { Button, InputNumber, Pagination, Progress, Tooltip, Empty, Input } from "antd";
import { GetAllProductForWebSite, GetToken } from "../../js/http-serve";
import { api } from "../../js/http-serve/ajax";
import { formatMaxProfitRate } from "../../utils";
import { User } from "../../service/user";

class Product extends Component {
    filterTabs = [
        {
            id: '',
            title: '全部'
        },
        {
            id: 'E6D14276-143A-4862-B79D-EE1B1719AFA3',
            title: '交易所'
        },
        {
            id: '30FE0567-DE35-4768-A322-C2D91D892B73',
            title: '信托'
        },
        {
            id: '0ACF6EBC-DFE5-4E5E-AE87-159ADADB16AB',
            title: '资管'
        },
        {
            id: 'A6EC5CA1-9D02-4E58-B87B-6ACB6C735006',
            title: '契约型基金'
        },
        {
            id: '66B020FA-9B4D-4242-BF3F-AA6B1A480B70',
            title: '有限合伙'
        },
        {
            id: '2E9B31C4-D138-4905-AEE2-BDC0261F587A',
            title: '其他'
        }
    ];

    constructor(props) {
        super(props);
        this.state = {
            isAuthentication: 0,
            CurrentTabIndex: 2,
            showSearchBar: true,
            query: {
                PageSize: 5,
                PageIndex: 1,
                TotalCount: 0,
                ProductType: '',
                InvestmentHorizonStart: '',
                InvestmentHorizonEnd: '',
                IncomeRateMin: '',
                IncomeRateMax: ''
            },
            productList: []
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        if (User.getUser().Token) {
            this.setState({
                isAuthentication: User.getUser().State
            })
            this.getList()
        } else {
            this.props.history.push('/login')
        }
    }

    componentWillUnmount() {

    }


    toDetail = (id) => {
        if (User.isLogin()) {
            this.props.history.push('/product/productDetail/' + id);
        } else {
            this.props.history.push('/login')
        }
    };

    getList = async () => {
        let { productList, query, CurrentTabIndex } = this.state
        let list = await GetAllProductForWebSite({
            ...query,
            Status: CurrentTabIndex
        });
        productList = list.Data
        query.TotalCount = list.TotalCount
        this.setState({
            productList,
            query
        })
    }

    //tab切换
    selectTab = (tab) => {
        let { CurrentTabIndex, query, } = this.state
        if (CurrentTabIndex === tab) return
        query.PageIndex = 1
        this.setState({
            query,
            CurrentTabIndex: tab
        }, () => {
            this.getList()
        })

    };

    //过滤按钮选择
    selectFilter = (tab) => {
        console.log(tab)
        let { query } = this.state
        if (query.ProductType === tab) return
        query.ProductType = tab
        this.setState({ query }, () => {
            this.getList()
        })
    };

    //点击页码回调
    SizeChange = (page, pageSize) => {
        let { query } = this.state
        query.PageIndex = page
        this.setState(query, () => {
            document.body.scrollTop = 400;
            document.documentElement.scrollTop = 400;
            this.getList()
        })
    };

    queryList = () => {
        let { query } = this.state
        query.PageIndex = 1
        this.setState({
            query
        }, () => {
            this.getList()
        })
    };

    reset = () => {
        let { query } = this.state
        query.PageIndex = 1
        query.InvestmentHorizonStart = ''
        query.InvestmentHorizonEnd = ''
        query.IncomeStart = ''
        query.IncomeEnd = ''
        query.ProductType = ''
        query.Name = ''
        this.setState({
            query
        }, () => {
            this.getList()
        })

    };

    handleEnterKey = (e) => {
        if (e.nativeEvent.keyCode === 13) { //e.nativeEvent获取原生的事件对像
            this.getList()
        }
    };


    render() {
        let { CurrentTabIndex, query, productList, isAuthentication } = this.state
        return (
            <div className={'product'}>
                <div className="topBg">
                    <p className='title'>以善为本&nbsp;&nbsp;以诚相交&nbsp;&nbsp;以信为重</p>
                </div>

                {
                    isAuthentication ? <div className="filterCondition">
                        <div className="tab">
                            <div className={`tabBtn ${CurrentTabIndex === 2 ? 'active' : ''}`}
                                onClick={() => this.selectTab(2)}>在售产品
                            </div>
                            <div className={`tabBtn ${CurrentTabIndex === 3 ? 'active' : ''}`}
                                onClick={() => this.selectTab(3)}>存续产品
                            </div>
                        </div>
                        <div className="filterContainer">
                            <div className="desc">发行通道：</div>
                            {
                                this.filterTabs.map((item, index) => {
                                    return (
                                        <div
                                            className={`filterBtn ${query.ProductType === item.id ? 'active' : ''}`}
                                            key={index}
                                            onClick={() => this.selectFilter(item.id)}
                                        >{item.title}</div>
                                    )
                                })
                            }
                        </div>
                        <div className="filterContainer mt20">
                            <div className="desc">投资期限：</div>
                            <div style={{ width: '200px' }}>
                                <InputNumber
                                    value={query.InvestmentHorizonStart}
                                    style={{ width: '200px' }} min={0} placeholder="月"
                                    onChange={value => {
                                        query.InvestmentHorizonStart = value
                                        this.setState({ query })
                                    }} />
                            </div>
                            &nbsp;&nbsp;-&nbsp;&nbsp;
                            <div style={{ width: '200px' }}>
                                <InputNumber
                                    value={query.InvestmentHorizonEnd}
                                    onChange={value => {
                                        query.InvestmentHorizonEnd = value
                                        this.setState({ query })
                                    }}
                                    style={{ width: '200px' }} min={0} placeholder="月" />
                            </div>
                        </div>
                        <div className="filterContainer mt20">
                            <div className="desc">收益率：</div>
                            <div style={{ width: '200px' }}>
                                <InputNumber style={{ width: '200px' }} placeholder="%"
                                    value={query.IncomeStart}
                                    onChange={value => {
                                        query.IncomeStart = value
                                        this.setState({ query })
                                    }}
                                />
                            </div>
                            &nbsp;&nbsp;-&nbsp;&nbsp;
                            <div style={{ width: '200px' }}>
                                <InputNumber
                                    value={query.IncomeEnd}
                                    onChange={value => {
                                        query.IncomeEnd = value
                                        this.setState({ query })
                                    }}
                                    style={{ width: '200px' }} placeholder="%" />
                            </div>
                        </div>
                        <div className="filterContainer mt20">
                            <div className="desc">产品名称：</div>
                            <div style={{ width: '422px' }}>
                                <Input style={{ width: '422px' }} value={query.Name} allowClear={true} placeholder="请输入产品名称"
                                    onChange={e => {
                                        e.persist()
                                        query.Name = e.target.value
                                        this.setState({ query })
                                    }}
                                />
                            </div>
                            <Button className={'ml-20'} type={'primary'} onClick={this.queryList}>查询</Button>
                            <Button className={'ml-20'} onClick={this.reset}>重置</Button>
                        </div>
                    </div> :
                        <div className="filterCondition2">
                            <div class="titleContent">您的账户正在审核中，审核通过后方可查看产品信息，请耐心等待！</div>
                        </div>
                }


                {productList.length > 0 && isAuthentication ?
                    productList.map((item, index) => {
                        return (
                            <div className="productList" key={item.ProductId}>
                                <div className="titleBox">
                                    <p className="title">{item.ProductName}</p>
                                    {
                                        item.ProductTypeDesc && item.ProductTypeDesc.length > 0 &&
                                        <div
                                            className="desc">{item.ProductTypeDesc[item.ProductTypeDesc.length - 1]}</div>
                                    }

                                </div>
                                <div className="infoMain">
                                    <div className="info">
                                        {
                                            item.IsFloat ?
                                                <p className="val">浮动</p>
                                                :
                                                <p className="val">{formatMaxProfitRate(item.MinProfitRate, item.MaxProfitRate)}</p>
                                        }
                                        <p className="key">业绩比较基准</p>
                                    </div>
                                    <div className="info">
                                        <p className="val">{item.InvestmentHorizon}</p>
                                        <p className="key">投资期限</p>
                                    </div>
                                    <div className="info">
                                        <p className="val">{item.InvestMin}万元</p>
                                        <p className="key">起投资金</p>
                                    </div>
                                    <div className="info">
                                        <div className="val" style={{ width: '100px' }}>
                                            <Tooltip
                                                title={'募集进度' + ((item.IssueCurrentMoney / item.IssueQuota) * 100).toFixed(2) + '%'}>
                                                <Progress percent={(item.IssueCurrentMoney / item.IssueQuota) * 100}
                                                    size="small" showInfo={false}
                                                    strokeColor="#EDB35B" />
                                            </Tooltip>
                                        </div>
                                        <p className="key">募集进度</p>
                                    </div>
                                    <div className="info">
                                        <p className={'val'}>
                                            <Button type={'primary'} onClick={() => this.toDetail(item.ProductId)}>查看</Button>
                                            {/*<div className="detailBtn"*/}
                                            {/*  >查看*/}
                                            {/*</div>*/}
                                        </p>
                                        <p className="key">&nbsp;</p>
                                    </div>

                                </div>
                            </div>
                        )
                    }) : isAuthentication ?
                        <div className={'empty'}>
                            < Empty />
                        </div>
                        : null

                }
                {
                    isAuthentication ?
                        <div className="pagination">
                            <Pagination
                                className={'mr-30'}
                                onChange={(page, pageSize) => this.SizeChange(page, pageSize)}
                                pageSize={query.PageSize}
                                current={query.PageIndex}
                                total={query.TotalCount}
                            />
                        </div> : null
                }
            </div >
        );
    }
}


export default withRouter(Product);

































