import React, { Component } from 'react';
import './company.less'
import ClassifyTitle from "../../components/classify-title";
import Anchor from "antd/es/anchor";
import { Carousel, Icon, Progress, Tooltip } from "antd";
import DisplayCards from "../../components/display-cards";
import { formatMaxProfitRate } from "../../utils";

const { Link } = Anchor;

class Company extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedId: 1,
            selectedPlate: 1,
            muted: false,
            plateList: [
                {
                    id: 1,
                    activeUrl: require('../../images/introduction.png'),
                    url: require('../../images/introduction2.png'),
                    isActive: true,
                },
                {
                    id: 2,
                    activeUrl: require('../../images/structure.png'),
                    url: require('../../images/structure2.png'),
                    isActive: false
                },
                {
                    id: 3,
                    activeUrl: require('../../images/team2.png'),
                    url: require('../../images/team.png'),
                    isActive: false
                },
                {
                    id: 4,
                    activeUrl: require('../../images/culutre.png'),
                    url: require('../../images/culture2.png'),
                    isActive: false
                },
            ]
        };
        this.personList = [
            {
                sort1: 0,
                sort2: 3,
                sort3: 3,
                sort4: 3,
                sort5: 3,
                sort6: 3,
                id: 1,
                name: '蔡肖羽',
                position: '联合创始人',
                src: require('../../images/cxyheader.png'),
                desc: '毕业于浙江大学。曾就职于国内大型金融机构，凭借其扎实的经济和金融专业知识以及敏锐的市场洞察力，专注为高净值客户进行资产配置，具有丰富的私人银行、财富管理和资产管理领域的从业经验。',
            },
            {
                sort1: 3,
                sort2: 0,
                sort3: 4,
                sort4: 2,
                sort5: 4,
                sort6: 4,
                id: 2,
                name: '张益龙',
                position: '联合创始人',
                src: require('../../images/zylheader.png'),
                desc: '本科毕业于华东政法大学，获得经济学、法学双学位，硕士毕业于英国诺丁汉大学金融学专业。曾任职于国内大型金融机构，在项目投资、管理、风险控制、项目资源整合等方面经验丰富，主持完成多个信托计划的募、投、管、退工作。'
            },
            {
                sort1: 4,
                sort2: 4,
                sort3: 0,
                sort4: 4,
                sort5: 1,
                sort6: 5,
                id: 3,
                name: '肖文虎',
                position: '贵州大区总经理',
                src: require('../../images/xwhheader.png'),
                desc: '十几年金融行业从业经历，曾先后就职于两家国有控股大型证券机构，主要担任资产配置管理工作，拥有丰富的一线资产配置经验，熟悉国内证券、期货等行业。善于在固定收益领域为合格投资者获取持续回报。'
            },
            {
                sort1: 2,
                sort2: 2,
                sort3: 2,
                sort4: 0,
                sort5: 2,
                sort6: 2,
                id: 4,
                name: '张胤珺',
                position: '金融业务部总裁',
                src: require('../../images/zyjheader.png'),
                desc: '曾任职于国内大型金融机构，十几年专注为高净值客户进行资产配置，拥有丰富的金融行业经验。曾任国有控股大型证券资产管理总部总经理，曾担任上海师范大学经济学院课外辅导老师，历年受邀出席CFO中国财富论坛峰会，曾多次担任财经频道嘉宾。'
            },
            {
                sort1: 1,
                sort2: 1,
                sort3: 1,
                sort4: 1,
                sort5: 0,
                sort6: 1,
                id: 5,
                name: '沈燕',
                position: 'CEO',
                src: require('../../images/shenyan.png'),
                desc: '本科毕业于浙江大学，研究生毕业于同济大学。2011年-2017年任职于某外资银行财富管理部门、信用卡和无担保贷款部门；参与银行多个QDII及国内公募基金产品的发行工作；担任信用卡中心产品经理，主导多张高端信用卡的国内首发项目并负责其在全国的推广工作，主要对接银行高端理财客户。'
            },
            {
                sort1: 5,
                sort2: 5,
                sort3: 5,
                sort4: 5,
                sort5: 5,
                sort6: 0,
                id: 6,
                name: '段公亮',
                position: '贵阳城市经理',
                src: require('../../images/dglheader.png'),
                desc: '十余年金融行业从业经验，拥有基金从业、 证券从业资格证。曾就职于国有控股大型商业银行，具有丰富的财富管理和资产管理领域的从业经验，擅长并专注为高净值客户进行固定收益领域资产配置。'
            },
        ];

        this.image = [
            require('../../images/activity2.png'),
            require('../../images/activity6.jpg'),
            require('../../images/activity4.jpeg'),
            require('../../images/activity7.JPG'),
            require('../../images/activity13.JPG'),
            require('../../images/activity5.jpeg'),
            require('../../images/activity8.JPG'),
            require('../../images/activity11.JPG'),
            require('../../images/activity14.jpg'),
            require('../../images/activity3.png'),
            require('../../images/activity1.png'),
            require('../../images/activity9.JPG'),
            require('../../images/activity12.JPG'),
            require('../../images/activity15.JPG'),
            require('../../images/activity10.JPG'),

        ]
    }

    setMute = () => {
        let a = document.querySelector('#video');
        a.muted = !a.muted;
        this.setState({
            muted: a.muted
        })
    };

    renderPerson = (props) => {
        return (
            <div key={props.id} className={'person'} onClick={() => {
                this.setState({
                    selectedId: props.id
                })
            }}>
                <img className={'header'} src={props.src} alt="" />
                <p className="name">{props.name}</p>
                <p className='position'>{props.position}</p>
            </div>
        )
    };

    renderHugePerson = (props) => {
        return (
            <div key={props.id}>
                <img className={'header'} src={props.src} alt="" />
                <p className="name">{props.name}</p>
                <p className='position'>{props.position}</p>
                <p className='desc'>{props.desc}</p>
            </div>
        )
    };


    render() {
        let { selectedId, selectedPlate } = this.state
        return (
            <div className='company'>
                <div className="banner">
                    <div className={'video'}>
                        <video
                            controls={true}
                            poster={require('../../images/play.png')}
                            id="video" x5-playsinline="" playsInline={true} loop
                            className="w100">
                            <source
                                src="http://cbs.starcapitalchina.com/uploadfiles/movies/companyvideomax.mp4"
                                type="video/mp4" />
                        </video>
                    </div>
                </div>
                <div>
                    <div className={'company-trends'}>
                        <div id='company'>
                            <ClassifyTitle cn="走进信始" en="COMPANY PROFILE" className="title" />
                            <div className={'news-type'}>
                                {this.state.plateList.map((item, i) => {
                                    return <img
                                        key={i}
                                        onClick={() => {
                                            this.state.plateList.forEach((el, index) => {
                                                el.isActive = index === i
                                            });

                                            this.setState({
                                                selectedPlate: item.id,
                                                plateList: this.state.plateList
                                            })
                                        }}
                                        className={item.isActive ? 'img-active' : ''}
                                        src={item.isActive ? item.activeUrl : item.url} alt="" />
                                })}
                            </div>
                        </div>
                        {this.state.selectedPlate === 1 &&
                            <div className={'company-box mt-50'}>
                                <p className={'pre-text'}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span
                                        className={'bold'}> 信始财富(Star Capital Co., Ltd.)</span>成立于2014年，公司根据国家监管部门的相关规定，致力于使用标准化债券、资产管理计划、契约型基金等金融工具为合格的机构及个人的资产配置需求提供专业的咨询服务。公司目前在上海、广东、浙江、贵州、辽宁等拥有多家分支机构。
                                </p>
                                <p className={'pre-text'}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 信始财富具有产业链式的团队配置，在项目承揽、承做、承销、投后管理方面具有全面的综合竞争力，形成了一体化、全方位的投融资服务。公司积极落实《关于规范金融机构资产管理业务的指导意见》的相关要求，合规备案，严控风险，切实保障合格投资者的合法权益
                                </p>
                                <p className={'pre-text'}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;信始财富紧跟金融发展政策，定期开展合规及金融知识培训，全面提升专业能力，夯实金融业务基础，致力于成为引领行业进步发展的金融服务企业。
                                </p>
                            </div>}
                        {this.state.selectedPlate === 2 &&
                            <img
                                src={require('../../images/map.png')} alt="" className={'w100 company-map'} />}
                    </div>
                    {this.state.selectedPlate === 3 && <div className={'core-bg mb-100'} id='team'>
                        <div className={'team-top'}>
                            {this.personList.sort((prev, next) => prev['sort' + this.state.selectedId] - next['sort' + this.state.selectedId]).map((el, index) => {
                                return el.id !== selectedId && this.renderPerson(el)
                            })}
                        </div>
                        <div className={'team-bottom'}>
                            <div className={'huge-person'}>
                                {this.renderHugePerson(
                                    this.personList.find(el => el.id === selectedId)
                                )}
                            </div>
                        </div>
                    </div>}
                    {this.state.selectedPlate === 4 &&
                        <div className={'company-trends'} style={{ position: 'relative', top: -75, marginBottom: 50 }}>
                            <div className={'text-desc'}>
                                <div className={'block'}>
                                    <p className={'block-title'}>企业愿景</p>
                                    <p className={'block-desc'}>尽心尽责，为合格投资者提供资产管理咨询服务</p>
                                </div>
                                <div className={'block'}>
                                    <p className={'block-title'}>企业使命</p>
                                    <p className={'block-desc '}>致力于成为专业、活力、值得合格投资者信赖的资产管理人</p>
                                </div>
                                <div className={'block'}>
                                    <p className={'block-title'}>企业价值观</p>
                                    <p className={'block-desc '}>求实、进取、协同、分享、结果</p>
                                </div>

                            </div>
                            <div className={'carousel'}>
                                <img src={require('../../images/arrow-left.png')} className={'icon-left'}
                                    onClick={() => this.carouse.prev()} />
                                <Carousel
                                    ref={ref => this.carouse = ref}
                                    dots={false}
                                    centerMode={true}
                                    className={'img-center'}
                                    centerPadding={'10px'}
                                    slidesToShow={3}
                                    autoplay={true}
                                >
                                    {this.image.map((img, index) => {
                                        return (
                                            <div className={'img-box'} key={index}>
                                                <img src={img} alt="" className={'img-swiper'} />
                                            </div>
                                        )
                                    })}
                                </Carousel>
                                <img src={require('../../images/arrow-right.png')} className={'icon-right'}
                                    onClick={() => this.carouse.next()} />
                            </div>
                        </div>}
                </div>

            </div >
        );
    }
}

export default Company;
