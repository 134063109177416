import React, {Component} from 'react';
import {withRouter} from "react-router-dom";

import './articleDetail.less';
import {Button, InputNumber, Pagination, Progress, Tooltip, Empty} from "antd";
import {ShowArticleDetailFront} from "../../../js/http-serve";
import {getDay, getMonth} from "../../../utils";
import moment from "moment";
import config from '../../../js/config/index'

class ArticleDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {
            Detail: {}
        }
        this.ArticleId = ''
    }

    componentWillMount() {
        this.ArticleId = window.location.href.split('/news/newsDetail/articleDetail/')[1]
    }

    componentDidMount() {
        document.scrollingElement.scrollTop = 0
        this.ShowArticleDetailFront()
    }

    ShowArticleDetailFront = async () => {
        let data = {
            ArticleId: this.ArticleId
        }
        let Detail = await ShowArticleDetailFront(data)
        Detail.Content = Detail.Content.replace(/(src=\")/g, 'style="max-width:100%;margin-top:10px" src="' + config.apiUrl + '/')
        this.setState({Detail})
    }


    render() {
        let {Detail} = this.state
        return (
            <div className={'ArticleDetail'}>
                <p className='navTitle'>您所在位置：新闻中心&nbsp;&#62;&nbsp;<span
                    className='navTitleSpe'>{Detail.Title || '新闻详情'}</span></p>
                <div className="container">
                    <h2 className="title">{Detail.Title}</h2>
                    <div className='header'>
                        <span className="time">发布时间：{moment(Detail.CreateTime).format('YYYY-MM-DD HH:mm:ss')}</span>
                        {
                            Detail.Source && <span className="source">来源：{Detail.Source}</span>
                        }
                    </div>
                    <div dangerouslySetInnerHTML={{__html: Detail.Content}}></div>
                </div>
            </div>
        );
    }
}


export default withRouter(ArticleDetail);
