import React, { Component } from 'react';
import './login.less';
import { Form, Icon, Input, Button, Checkbox, message } from 'antd';
import { LoginUser, RegisterUser, SendIdentifyCode, LoginMessage } from "../../js/http-serve";
import { User } from "../../service/user";
import { api } from "../../js/http-serve/ajax";
import { withRouter } from 'react-router-dom';
import Statement from "../statement/statement";


class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loginStatus: 0, //0手机号登录 1短信登录 2注册
            isSend: false,
            isSending: false,
            time: 0,
            isShowStatement: false,
            form: {
                Mobile: '',
                IdentifyingCode: '',
                Password: '',
                PasswordIden: '',
                agreement: false,
            }
        };
        this.isLogin = false;
        this.timer = null
    }

    componentWillUnmount() {
        this.isLogin = false;
        clearTimeout(this.timer);
    }

    //登录
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.loginUser(values)
            }
        });
    };
    //登录
    loginUser = async (val) => {
        let user = null;
        try {
            if (this.isLogin) return;
            this.isLogin = true;
            if (this.state.loginStatus === 1) {
                user = await LoginMessage(val)
            } else if (this.state.loginStatus === 0) {
                user = await LoginUser(val)
            }
            if (user) {
                if (user.UserStatus === 2) {
                    User.setUser(user);
                    api.setToken(user.Token);
                    this.isLogin = false;
                    this.props.history.replace("/")
                }
            }
            this.isLogin = false
        } catch (e) {
            this.isLogin = false
        }
    };

    //注册
    toRegister = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                if (!(/^1[23456789]\d{9}$/.test(values.Mobile))) {
                    message.error('请输入正确的手机号！');
                    return
                }
                if (!values.agreement) {
                    message.error('请同意使用协议！');
                    return
                }

                let data = Object.assign(values, { UserStatus: 2 })
                // let register = await RegisterUser(data)
                this.register(data)
            }
        });
    };

    register = async (data) => {
        let user = null;
        try {
            if (this.isLogin) return;
            this.isLogin = true;
            user = await RegisterUser(data);
            if (user) {
                User.setUser(user);
                api.setToken(user.Token);
                this.isLogin = false;
                this.props.history.replace("/")
            }
        } catch (e) {
            this.isLogin = false
        }

    };

    //与第一个密码对比
    compareToFirstPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('Password')) {
            callback('两次密码不一致！');
        } else {
            callback();
        }
    };
    //与第二个密码对比
    validateToNextPassword = (rule, value, callback) => {
        const { form } = this.props;
        if (value) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };

    //获取验证码
    SendIdentifyCode = async () => {
        let { form } = this.props
        if (!form.getFieldValue('Mobile')) {
            message.info('请先填写手机号！');
            return
        }
        let data = {
            Mobile: form.getFieldValue('Mobile')
        };
        await SendIdentifyCode(data);
        message.success('验证码发送中！');
        this.setState({
            isSend: true,
            isSending: false,
            time: 60
        });
        this.coolDown()
    };
    //验证码倒计时
    coolDown = () => {
        this.timer = setTimeout(() => {
            if (this.state.time <= 0) {
                clearTimeout(this.timer);
                this.setState({
                    isSend: false
                });
                return;
            }
            this.setState({
                time: --this.state.time
            });
            this.coolDown();
        }, 1000)
    };

    //改变登录方式或注册
    toChangeStatus = (loginStatus) => {
        this.setState({ loginStatus })
    };

    //返回首页
    toHome = () => {
        this.props.history.replace("/")
    };

    agree = (res) => {
        this.setState({
            isShowStatement: false,
        }, () => {
            this.props.form.setFieldsValue({
                'agreement': true,
                'Mobile': this.state.form.Mobile,
                'IdentifyingCode': this.state.form.IdentifyingCode,
                'Password': this.state.form.Password,
                'PasswordIden': this.state.form.PasswordIden,
            })
        })

        // this.props.form.setValue('agreement.checked',true)
    };

    render() {
        const { loginStatus, time, isSend, isSending } = this.state;
        const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

        return (
            <div className="login">
                <header className="header">
                    <div className="info-wrap">
                        <div className="info">
                            <img src={require('../../images/xs-logo.png')} className="tel"
                                onClick={() => this.toHome()} />
                            <div>
                                <span>欢迎登录</span>
                                <span className="vertical-line"> | </span>
                                <p className="hot-line">
                                    <i></i>
                                    <span>服务热线：400-6277-108</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </header>
                {this.state.isShowStatement ? <div className={'statement'}>
                    <Statement agree={this.agree} />
                </div> : <div className="login-bg">
                    {
                        loginStatus === 0 &&
                        <Form onSubmit={this.handleSubmit} className="login-register">
                            <p className={'login-title'}>用户名密码登录</p>
                            <Form.Item className={'form-item'}>
                                {getFieldDecorator('UserAccount', {
                                    rules: [{ required: true, message: '请输入手机号' }],
                                })(
                                    <Input
                                        className={'form-item-input'}
                                        placeholder="请输入手机号"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item className={'form-item'}>
                                {getFieldDecorator('Password', {
                                    rules: [{ required: true, message: '请输入密码' }],
                                })(
                                    <Input
                                        className={'form-item-input'}
                                        type="password"
                                        placeholder="请输入密码"
                                    />,
                                )}
                            </Form.Item>
                            <div className={'login-button'}>
                                <span onClick={() => this.toChangeStatus(2)}>立即注册</span>
                                <span onClick={() => this.toChangeStatus(1)}>免密码登录</span>
                            </div>
                            <Button
                                type={'primary'} htmlType="submit" className="login-form-button">
                                登录
                            </Button>
                        </Form>
                    }
                    {
                        loginStatus === 1 &&
                        <Form onSubmit={this.handleSubmit} className="login-register">
                            <p className={'login-title'}>短信登录</p>
                            <Form.Item className={'form-item'}>
                                {getFieldDecorator('Mobile', {
                                    rules: [{ required: true, message: '请输入手机号' }],
                                })(
                                    <Input
                                        className={'form-item-input'}
                                        placeholder="请输入手机号"
                                    />,
                                )}
                            </Form.Item>
                            <div className='flex'>
                                <Form.Item className='form-item flex1'>
                                    {getFieldDecorator('IdentifyingCode', {
                                        rules: [{ required: true, message: '请输入验证码' }],
                                    })(
                                        <Input
                                            className={'form-item-input'}
                                            placeholder="请输入验证码"
                                        />,
                                    )}
                                </Form.Item>
                                {
                                    isSend ?
                                        <div className='code'>已发送({time})</div>
                                        :
                                        (
                                            isSending ?
                                                <div className='code'>发送中</div>
                                                :
                                                <div className='code'
                                                    onClick={() => this.SendIdentifyCode()}>获取验证码</div>
                                        )
                                }

                            </div>
                            <div className={'login-button'}>
                                <span onClick={() => this.toChangeStatus(2)}>立即注册</span>
                                <span onClick={() => this.toChangeStatus(0)}>用户名密码登录</span>
                            </div>
                            <Button type={'primary'} htmlType="submit" className="login-form-button">
                                登录
                            </Button>
                        </Form>
                    }
                    {
                        loginStatus === 2 &&
                        <Form onSubmit={this.toRegister} className="login-register">
                            <p className={'login-title'}>手机号注册</p>
                            <Form.Item className={'form-item'}>
                                {getFieldDecorator('Mobile', {
                                    rules: [{ required: true, message: '请输入手机号' }],
                                })(
                                    <Input
                                        onChange={(ev) => {
                                            this.setState({ form: { ...this.state.form, Mobile: ev.target.value } })
                                        }}
                                        className={'form-item-input'}
                                        placeholder="请输入手机号"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item className={'form-item'}>
                                {getFieldDecorator('Password', {
                                    rules: [
                                        { required: true, message: '请输入密码' },
                                        { validator: this.validateToNextPassword }
                                    ],
                                })(
                                    <Input.Password
                                        onChange={(ev) => this.setState({
                                            form: {
                                                ...this.state.form,
                                                Password: ev.target.value
                                            }
                                        })}
                                        className={'form-item-input'}
                                        placeholder="请输入密码"
                                    />,
                                )}
                            </Form.Item>
                            <Form.Item className={'form-item'}>
                                {getFieldDecorator('PasswordIden', {
                                    rules: [
                                        { required: true, message: '请再次输入密码' },
                                        { validator: this.compareToFirstPassword }
                                    ],
                                })(
                                    <Input.Password
                                        onChange={(ev) => this.setState({
                                            form: {
                                                ...this.state.form,
                                                PasswordIden: ev.target.value
                                            }
                                        })}
                                        className={'form-item-input'}
                                        placeholder="请再次输入密码"
                                    />,
                                )}
                            </Form.Item>
                            <div className='flex'>
                                <Form.Item className='form-item flex1'>
                                    {getFieldDecorator('IdentifyingCode', {
                                        rules: [{ required: true, message: '请输入验证码' }],
                                    })(
                                        <Input
                                            onChange={(ev) => this.setState({
                                                form: {
                                                    ...this.state.form,
                                                    IdentifyingCode: ev.target.value
                                                }
                                            })}
                                            className={'form-item-input'}
                                            placeholder="请输入验证码"
                                        />,
                                    )}

                                </Form.Item>
                                {
                                    isSend ?
                                        <div className='code'>已发送({time})</div>
                                        :
                                        (
                                            isSending ?
                                                <div className='code'>发送中</div>
                                                :
                                                <div className='code'
                                                    onClick={() => this.SendIdentifyCode()}>获取验证码</div>
                                        )
                                }

                            </div>
                            <Form.Item style={{ marginBottom: 0 }}>
                                {getFieldDecorator('agreement', {
                                    valuePropName: 'checked',
                                })(
                                    <Checkbox
                                        onChange={(ev) => this.setState({
                                            form: {
                                                ...this.state.form,
                                                agreement: ev.target.value
                                            }
                                        })}
                                    >
                                        我已阅读并同意 <a onClick={() => this.setState({ isShowStatement: true })}>《信始财富使用协议》</a>
                                    </Checkbox>,
                                )}
                            </Form.Item>
                            <Button type={'primary'} htmlType="submit" className="login-form-button">
                                注册
                            </Button>
                            <div className={'text-right pointer mt-5 text-primary'}>
                                <span onClick={() => {
                                    this.setState({
                                        loginStatus: 0
                                    })
                                }}>已有账号?立即登录</span>
                            </div>
                        </Form>
                    }
                </div>}


                <div className="footer">
                    <p>
                        市场有风险 投资需谨慎 过往业绩不代表未来收益
                    </p>
                    <p>
                        &copy;2014-2022 &nbsp;&nbsp;Star Capital Co.Ltd&nbsp;&nbsp;  All rights reserved
                    </p>
                    <p><a target="_blank" href="http://beian.miit.gov.cn/" style={{ color: 'rgb(71, 71, 71)', cursor: 'pointer' }}>黔ICP备2022006340号-3</a></p>
                </div>
            </div>
        );
    }
}

export default Form.create({ name: 'login' })(withRouter(Login));
