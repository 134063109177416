import React, {Component} from 'react';
import './index.less';
import {UploadFile} from '../../../components/upload-file';
import {Upload, Icon, message} from 'antd';
import {SaveNewInvestorCertification, GetInvestorCertification} from '../../../js/http-serve';
import moment from 'moment';

class MyKyc extends Component {
    state = {
        type: 1,
        isEditing: false,
        file1: [],
        file2: [],
        file3: [],
        hasIc: false,
    }

    componentWillMount() {
        if (this.props.center.IcId) {
            this.setState({
                hasIc: true
            })
        }
    }

    getInvestorCertification = async () => {
        if (this.props.center.IcId) {//如果做了kyc 查询出来
            let res = await GetInvestorCertification({
                Id: this.props.center.IcId,
                IsIsp: this.props.center.IcStatus === 5
            });
            if (res.CertificationType == 1) {
                this.setState({
                    type: 1,
                    file1: res.AssetProof,
                    file2: res.Attachment
                });
            } else {
                this.setState({
                    type: 2,
                    file1: res.AssetProof,
                    file2: res.Attachment,
                    file3: res.ExperienceProof
                });
            }


        }
    }

    async componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.center.IcId) {
            this.setState({
                hasIc: true
            }, () => {
                this.getInvestorCertification()
            })
        }
    }


    async componentDidMount() {
        this.getInvestorCertification()
    }


    changeType = (i) => {
        if (this.state.isEditing) {
            return message.warning('请先保存当前操作');
        }
        this.setState({
            type: i

        });
    };
    edit = () => {
        this.setState({
            isEditing: true
        });
    };
    save = async () => {
        this.setState({
            isEditing: false
        });
        let data;
        if (this.state.type == 1) {
            data = {
                AssetProof: this.state.file1,
                Attachment: this.state.file2,
                CertificationType: 1,
                ExperienceProof: []

            };
        } else {
            data = {
                AssetProof: this.state.file1,
                Attachment: this.state.file2,
                CertificationType: 2,
                ExperienceProof: this.state.file3
            };
        }

        let res = await SaveNewInvestorCertification(data);
        await this.props.getCenter(async () => {
            await this.getInvestorCertification()
        })
    };
    cancel = () => {
        this.setState({
            isEditing: false
        });
        this.getInvestorCertification()
    };
    //上传成功后的file,变化的index
    uploadChange1 = (type, info) => {
        let t = this.state.file1.concat();
        if (type == 'add') {
            t.push({
                Url: info.file.response.Data[0].FilePath,
                Name: info.file.response.Data[0].FileName,
                UploadTime: moment().format('YYYY-MM-DD')
            });

        }
        if (type == 'delete') {
            t.splice(info);
        }
        this.setState({
            file1: t
        });
    };
    uploadChange2 = (type, info) => {
        let t = this.state.file2.concat();
        if (type == 'add') {
            t.push({
                Url: info.file.response.Data[0].FilePath,
                Name: info.file.response.Data[0].FileName,
                UploadTime: moment().format('YYYY-MM-DD')
            });

        }
        if (type == 'delete') {
            t.splice(info);
        }
        this.setState({
            file2: t
        });
    };
    uploadChange3 = (type, info) => {
        let t = this.state.file3.concat();
        if (type == 'add') {
            t.push({
                Url: info.file.response.Data[0].FilePath,
                Name: info.file.response.Data[0].FileName,
                UploadTime: moment().format('YYYY-MM-DD')
            });

        }
        if (type == 'delete') {
            t.splice(info);
        }
        this.setState({
            file3: t
        });
    };

    render() {
        const {type, isEditing, file1, file2, file3} = this.state;
        return (
            <div className="my-kyc">
                <div>
                    <div className="title">投资者类型</div>
                    <div className="type">
                        <span onClick={this.changeType.bind(this, 1)} className={type == 1 ? 'active' : ''}>普通投资者</span>
                        <span onClick={this.changeType.bind(this, 2)} className={type == 2 ? 'active' : ''}>专业投资者</span>
                    </div>
                    <div>
                        <div className="operators">
                            {
                                isEditing && <button className="btn" onClick={this.cancel}>取消</button>
                            }
                            {
                                isEditing && <button className="btn" onClick={this.save}>保存</button>
                            }
                            {
                                !isEditing && <button className="btn" onClick={this.edit}>编辑</button>
                            }
                        </div>
                        <UploadFile title="资产认证扫描件"
                                    desc={`请提供金额不低于${type == 1 ? 300 : 500}万元的资产证明，或者最近3年个人年均收入不低于30万元的收入证明`}
                                    canedit={isEditing} onChange={this.uploadChange1} imgUrls={file1} key={1}
                        />
                        {
                            type == 1 ? null :
                                <UploadFile title="经历认证扫描件" desc="请提供经历认证扫描件" className="m-t" key={3}
                                            canedit={isEditing} onChange={this.uploadChange3} imgUrls={file3}
                                />
                        }

                    </div>
                    <UploadFile title="资料附件" desc="请提供资料附件" className="m-t" key={2}
                                canedit={isEditing} onChange={this.uploadChange2} imgUrls={file2}
                    />
                </div>
            </div>
        );

    }
}


export default MyKyc;
