import React from 'react';
import ReactDOM from 'react-dom';
import './theme.less';
import './index.less';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router} from "react-router-dom";
import {createBrowserHistory} from "history";
// import mock from './mock/mock.js';
import {ConfigProvider} from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';


const supportsHistory = 'pushState' in window.history
// mock();
let history = createBrowserHistory();


//全局只要1个Router
ReactDOM.render(
    <Router history={history} forceRefresh={!supportsHistory}>
        <ConfigProvider locale={zh_CN}>
            <App/>
        </ConfigProvider>
    </Router>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
