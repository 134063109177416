import axios from 'axios';
import config from '../config';
import {Modal} from 'antd';
import {createBrowserHistory} from 'history';
import {User} from "../../service/user";

const history = createBrowserHistory();
// export async function post(url, data, successFn, failFn) {
//     return axios({
//         method: 'post',
//         url: config.apiUrl + url,
//         data: data,
//     }).then((res) => {
//         console.log(url + '-->:', res);
//         successFn && successFn(res.data.Data, res);
//     }).catch((error) => {
//         console.error(url + '-->:', error);
//         failFn && failFn(error);
//     })
// }

axios.interceptors.request.use(config => {
    let pathname = window.location.pathname;
    if (pathname === '/center') {
        let user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : ''
        if (!user) {
            window.location.href = '/login'
        }
        //  history.push('/login');
    }
    return config;
}, error => {
    // 对请求错误做些什么
    return Promise.reject(error);
});

const api = {
    token: '',
    setToken(token) {
        this.token = token
    },
    getToken() {
        return this.token
    },
    async get(url, data) {
        try {
            let res = await axios.get(config.apiUrl + url, {params: data})
            res = res.data
            return new Promise((resolve) => {
                if (res.code === 0) {
                    resolve(res)
                } else {
                    resolve(res)
                }
            })
        } catch (err) {
            alert('服务器出错')
        }
    },
    async post(url, data) {
        // let user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : ''
        // let Token = ''
        // Token = user ? user.Token : sessionStorage.getItem("Token")
        try {
            let res = await axios.post(config.apiUrl + url, data, {
                headers: {
                    Authorization: 'Bearer ' + this.getToken()
                },
                timeout: 20000,
            })
            res = res.data
            return new Promise((resolve, reject) => {
                if (res.Code === '200' && res.IsSuccess === true) {
                    resolve(res.Data)
                } else if (res.Code === '0') {
                    User.clearUser()
                    Modal.error({
                        title: '抱歉',
                        content: res.Message
                    })
                    if (res.Message === '用户未登录') {
                        history.replace('/')
                        window.refresh()
                    }
                    reject(res)
                }
            })
        } catch (err) {
            if (err.response.status === 401) {
                sessionStorage.removeItem("user");
                this.setToken('')
                window.location.href = '/login'
            }
            return err
        }
    },
}
export {api}
