import React, { Component } from 'react';
import './App.less';
import { Route, withRouter, Switch } from "react-router-dom";
import { Modal } from 'antd'
import Home from './pages/home/home';
import Product from './pages/product/product';
import ProductDetail from './pages/product/productDetail/productDetail'
import News from './pages/news/news';
import ArticleDetail from './pages/news/newsDetail/articleDetail';
import Company from './pages/company/company';
import Center from './pages/center/center';
import Login from './pages/login/login';
import moment from 'moment'
import 'moment/locale/zh-cn';
import { User } from "./service/user";
import { api } from "./js/http-serve/ajax";
import { GetToken } from "./js/http-serve";
import UAParser from 'ua-parser-js'
import config from './js/config/index'
import Eula from "./pages/eula/eula";

moment.locale('zh-cn');

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navItems: [
                {
                    text: '首页',
                    id: '0',
                    path: '/'
                },
                {
                    id: '1',
                    text: '产品中心',
                    path: '/product'
                },
                {
                    id: '2',
                    text: '新闻中心',
                    path: '/news'
                },
                {
                    id: '3',
                    text: '走进信始',
                    path: '/company'
                },
                {
                    id: '4',
                    text: '个人中心',
                    path: '/center'
                },
            ],
            selectId: '0',
            isLogined: false,
            hasToken: false
        };
    }


    selectNav(item) {
        this.setState({
            selectId: item.id
        }, () => {

        });
        this.props.history.push(item.path)
    }

    componentWillMount() {
        let parser = new UAParser();
        if (parser.getResult().device.model) {
            window.location.href = config.mobileHttp
        }
        let href = window.location.href;
        let id = '0';
        if (href.indexOf('product') > -1) {
            id = '1'
        } else if (href.indexOf('news') > -1) {
            id = '2'
        } else if (href.indexOf('company') > -1) {
            id = '3'
        } else if (href.indexOf('center') > -1) {
            id = '4'
        } else {
            id = '0'
        }
        this.setState({
            selectId: id
        });
        this.validIsLogin()
    }


    componentDidMount() {

        this.listener = this.urlFit();
    }

    componentWillReceiveProps() {
        this.validIsLogin()
    }

    urlFit = () => {
        let i = 0;
        return this.props.history.listen((location, action) => {
            if (location.pathname === '/') {
                i = 0;
            } else if (location.pathname.indexOf('/product') > -1) {
                i = 1;
            } else if (location.pathname.indexOf('/news') > -1) {
                i = 2;
            } else if (location.pathname.indexOf('/company') > -1) {
                i = 3;
            } else if (location.pathname.indexOf('/center') > -1) {
                i = 4;
            }
            this.setState({
                selectId: i + ''
            });
        });

    };

    validIsLogin = () => {
        // let user = sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : ''
        let user = User.getUser();
        if (user.UserStatus === 2) {
            api.setToken(user.Token);
            this.setState({
                isLogined: true,
                user: user,
                hasToken: true,
            })
        } else {
            this.getToken();
            this.setState({
                isLogined: false,
            })
        }

    };


    getToken = async () => {
        let token = await GetToken({ UserStatus: 1 })
        api.setToken(token)
        this.setState({ hasToken: true })
    };

    componentWillUnmount() {
        this.listener();
    }


    //前往登陆
    toLogin() {
        this.props.history.replace('/login');
    }

    //退出登录
    toLogOut() {
        Modal.confirm({
            title: '退出登录',
            content: '即将退出登录,请确认。',
            onOk: () => {
                sessionStorage.removeItem('user');
                User.clearUser();
                this.validIsLogin();
                this.props.history.replace('/')
            }
        })

    }

    render() {
        const { navItems, selectId, isLogined, user } = this.state;
        return (

            <Switch>
                <Route exact path="/login" component={Login} />
                <Route path="/eula" component={Eula} />
                <Route exact path="" render={() => {
                    return (
                        <>
                            {this.state.hasToken &&
                                <div className="App">
                                    <header className="header">
                                        <div className="info-wrap">
                                            <div className="info">
                                                <img src={require('./images/telphone.png')} className="tel" />
                                                <p className="hot-line">服务热线：400-6277-108</p>
                                                <div className="wechat-wrap">
                                                    <img src={require('./images/wechat-white.png')}
                                                        className="wechat-white" />
                                                    <img src={require('./images/wechat.png')} className="wechat" />
                                                    <div className='qrCode'>
                                                        <img src={require('./images/wechat-mp.jpg')}
                                                            className="wechat-fwh" />
                                                        <p style={{
                                                            lineHeight: '15px',
                                                            color: '#8D6F4A',
                                                            fontSize: '14px'
                                                        }}>信始i财富</p>
                                                    </div>
                                                </div>
                                                <div className="mobile-wrap">
                                                    <img src={require('./images/mobile-white.png')}
                                                        className="mobile-white" />
                                                    <img src={require('./images/mobile.png')} className="mobile" />
                                                    <div className='qrCode'>
                                                        <img src={require('./images/wechat-app.png')}
                                                            className="mobile-fwh" />
                                                        <p style={{
                                                            lineHeight: '15px',
                                                            color: '#8D6F4A',
                                                            fontSize: '14px'
                                                        }}>信始财富</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    {
                                                        isLogined ?
                                                            <div>
                                                                <span className="user">您好，{user.UserName}</span>
                                                                &nbsp;|&nbsp;
                                                                <span className="logout"
                                                                    onClick={this.toLogOut.bind(this)}>退出</span>
                                                            </div>
                                                            :
                                                            <span className="user"
                                                                onClick={this.toLogin.bind(this)}>您好，请登录</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="nav-wrap">
                                            <div className="nav">
                                                <img src={require('./images/xs-logo.png')} className="xs-logo" />
                                                <ul className="navigator">
                                                    {
                                                        navItems.map((item, index) => {
                                                            return (<li key={index}
                                                                className={item.id == selectId ? 'active' : ''}
                                                                onClick={() => {
                                                                    if (!User.isLogin() && item.id === '4') {
                                                                        return this.props.history.push('/login')
                                                                    } else {
                                                                        this.selectNav(item)
                                                                    }
                                                                }}>{item.text}</li>)
                                                        })
                                                    }

                                                </ul>
                                            </div>
                                        </div>
                                    </header>
                                    <div className="body-content">
                                        {/*<Redirect exact from={'/'} to={'/index'}/>*/}
                                        <Route exact path='/' component={Home} />
                                        {/*<Route exact path='/index' component={Home}/>*/}
                                        <Route exact path="/product" component={Product} />
                                        <Route exact path="/product/productDetail/:id" component={ProductDetail} />
                                        <Route exact path="/news" component={News} />
                                        <Route exact path="/news/newsDetail/articleDetail/:id"
                                            component={ArticleDetail} />
                                        <Route exact path="/company" component={Company} />
                                        <Route path="/center" component={Center} />
                                    </div>
                                    <div className="footer-wrap">
                                        <footer className="footer">
                                            <div className={'left'}>
                                                <h4>友情链接</h4>
                                                <a href="http://www.csrc.gov.cn/pub/newsite/"
                                                    target="_blank">中国证券监督管理委员会</a>
                                                <a href="http://www.amac.org.cn/" target="_blank">中国证券投资基金业协会</a>
                                                <a href="http://www.pbccrc.org.cn/" target="_blank">中国人民银行征信中心</a>
                                                <h4>地址</h4>
                                                <p>集团总部地址： 上海市虹口区四川北路1363号壹丰广场908室</p>
                                                <p>贵阳分公司地址：贵州省贵阳市中华中路1号峰会国际大厦B栋13层1号</p>
                                                <p>凯里分公司地址：贵州省凯里市宁波路五号坐标广场大厦A座八楼</p>
                                                <p>毕节分公司地址：贵州省毕节市七星关区百里杜鹃路国贸中心19楼</p>
                                                <p>织金分公司地址：贵州省织金县新华北路惠友广场8-3</p>
                                                <p>遵义分公司地址：贵州省遵义市汇川区厦门路天安大厦16楼</p>
                                                <p>肇庆分公司地址：广东省肇庆市端州区星湖西路1号深圳湾1栋2楼</p>
                                                <h4 style={{ marginTop: '20px' }}>服务热线</h4>
                                                <p className="time">400-6277-108&nbsp;&nbsp;&nbsp;<span>（工作时间：每周一至周五 09:00~21:00）</span>
                                                </p>
                                                <div className="er-wrap">
                                                    <div>
                                                        <img src={require('./images/wechat-app.png')} alt="App" />
                                                        <span>App</span>
                                                    </div>
                                                    <div>
                                                        <img src={require('./images/wechat-mp.jpg')} alt="微信小程序" />
                                                        <span>小程序</span>
                                                    </div>
                                                    <div>
                                                        <img src={require('./images/wechat-fwh.png')} alt="微信公众号" />
                                                        <span>公众号</span>
                                                    </div>
                                                </div >
                                            </div>
                                            <div className={'right'}>
                                                <div>
                                                    <div>
                                                        <p>*「信始财富」商标™️已核准并授权使用；</p>
                                                        <p>* 投资有风险，投资需谨慎，过往业绩不代表未来收益。</p>
                                                    </div>
                                                    <div style={{ marginTop: '10px' }}>
                                                        <h4>免责说明</h4>
                                                        <p>1.本网站所涵盖的信息仅供与特定人员交流研讨，不得用于未经允许的其他任何用途。</p>
                                                        <p>2.本网站中部分信息来源于公开披露资料，本公司对该部分信息的准确性和完整性不做任何保证，也不保证该部分信息及相关建议不会发生任何变更，本公司已力求材料内容的客观、公正，但文中的观点及相关建议仅供参考，不代表任何确定性的判断。</p>
                                                        <p>3.本网站中涉及本公司的任何信息，不代表本公司发行的任何产品未来运作的实际效果和任何产品可能获得的实际收益，不同产品的投资回报均可能因宏观调控、市场环境等因素的变化而有所改变。</p>
                                                        <p>4.本网站及其内容均为保密信息，未经事先书面同意，本网站不可被复制或分发，本网站的内容亦不可向任何第三者披露，一旦阅读本资料，每一潜在阅读者应被视为已同意此项条款。</p>
                                                        <p>5.本网站所含信息仅供参考，均不具有任何法律效力，一切产品内容均以实际签署的协议、文件为准。</p>
                                                        <p>6.本网站中涉及的产品仅适合具有一定风险识别、风险评估和风险承受能力的合格投资者；投资者需对投资风险应有充分的理解和认识，投资有风险，投资需谨慎。</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </footer >
                                        <p style={{ textAlign: 'center' }}><a target="_blank" href="http://beian.miit.gov.cn/" style={{ color: 'rgb(255, 255, 255)' }}>黔ICP备2022006340号-3</a></p>
                                    </div >
                                </div >}
                        </>

                    );
                }} />
            </Switch >
        );
    }
}

export default withRouter(App);
