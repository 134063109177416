import moment from 'moment'
function formatMaxProfitRate(min, max) {
    if (min === max) {
        return min + '%'
    } else {
        return min + '% ~ ' + max + '%'
    }
}

function formatAmount(min, max) {
    if (min === max || max == null) {
        return min + '万元以上'
    } else {
        return min + '万元 ~ ' + max + '万元'
    }
}


function getMonth(date) {
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', "DEC"];
    if (date) {
        return months[new Date(date).getMonth()]
    } else {
        return ''
    }
}

function getDay(date) {
    if (date) {
        return moment(date).date()
    } else {
        return ''
    }

}

function period(period, unit) {
    const unitId = [{name: '年', unit: 1}, {name: '个月', unit: 2}, {name: '日', unit: 3}]
    const unitStr = unitId.find(el => el.unit === unit) ? unitId.find(el => el.unit === unit).name : ''
    return period + unitStr
}

function getFrequency(val) {
    const frequency = ["年付", "月付", "季付", "半年付", "到期兑付", "自然月付", "自然季付", "自然年付", "自然半年付"]
    if (val) {
        return frequency[val - 1]
    } else {
        return ''
    }
}

function getIsFloat(val) {
    const IsFloat = ['固定', '浮动', '固定加浮动']
    if (val) {
        return IsFloat[val - 1]
    } else {
        return ''
    }
}

export {formatMaxProfitRate, formatAmount, getMonth, getDay, period, getFrequency, getIsFloat}
