import {api} from './ajax';

export async function GetWebSiteProductList(query) {
    return await api.post('/api/WebSiteProduct/GetWebSiteProductList', query);
}

export async function GetHomePageInfoForApp(data) {
    return await api.post('/api/HomePage/GetHomePageInfoForApp', data);
}


export async function GetToken(data) {
    return await api.post('/api/Token/GetToken', data);
}

export async function GetPlateList() {
    return await api.post('/api/Plate/GetPlateList', {IsFromBack: false, PageSize: 9999, PageIndex: 1})
}

export async function GetPlateDetail(PlateId) {
    return await api.post('/api/Plate/GetPlateDetail', PlateId)
}

export async function GetVideoFrontList(PlateId) {
    return await api.post('/api/Video/GetVideoFrontList', PlateId)
}
export async function ShowVideoDetailFront(PlateId) {
  return await api.post('/api/Article/ShowVideoDetailFront', PlateId)
}


export async function GetArticleFrontList(PlateId) {
    return await api.post('/api/Article/GetArticleFrontList', PlateId)
}

export async function ShowArticleDetailFront(PlateId) {
  return await api.post('/api/Article/ShowArticleDetailFront', PlateId)
}

//UserAccount  Password
export async function LoginUser(data) {
    return await api.post('/api/ISPUser/LoginUser', data)
}

//Mobile  IdentifyingCode
export async function LoginMessage(data) {
    return await api.post('/api/ISPUser/LoginMessage', data)
}

//Mobile  Password IdentifyingCode UserStatus
export async function RegisterUser(data) {
    return await api.post('/api/ISPUser/RegisterUser', data)
}


//IdentifyingCode  Mobile
export async function SendIdentifyCode(data) {
    return await api.post('/api/ISPUser/SendIdentifyCode', data)
}

export async function ResetPassword(data) {
  return await api.post('/api/ISPUser/ResetPassword', data)
}


//IdentifyingCode Mobile
export async function ValidateIdentifyCode(data) {
    return await api.post('/api/ISPUser/ValidateIdentifyCode', data)
}

//
export async function GetIspUserInfoForApp() {
    return await api.post('/api/ISPUser/GetIspUserInfoForApp', {})
}

export async function GetIspUserManageCenterForApp() {
    return await api.post('/api/ISPUser/GetIspUserManageCenterForApp', {})
}

export async function GetEmployeeInfo() {
    return await api.post('/api/ISPUser/GetIspUserManageCenterForApp', {})
}

export async function GetRpqQuestionList() {
    return await api.post('/api/ISPUser/GetRpqQuestionList', {})
}

//AssessmentResultInfoId
export async function GetAssessmentResultInfo(data) {
    return await api.post('/api/ISPUser/GetAssessmentResultInfo', data)
}


//List<QuestionContentRqst>
export async function GetRpqResult(data) {
    return await api.post('/api/ISPUser/GetRpqResult', data)
}

//Id IsIsp
export async function GetInvestorCertification(data) {
    return await api.post('/api/ISPUser/GetInvestorCertification', data)
}

export async function Certification(data) {
  return await api.post('/api/ISPUser/Certification', data)
}

export async function UpdateGender(data) {
  return await api.post('/api/ISPUser/UpdateGender', data)
}

export async function UpdateNationality(data) {
  return await api.post('/api/ISPUser/UpdateNationality', data)
}

//CertificationType AssetProof ExperienceProof Attachment
export async function SaveNewInvestorCertification(data) {
    return await api.post('/api/ISPUser/SaveNewInvestorCertification', data)
}

//CertificationType AssetProof ExperienceProof Attachment
export async function GetAllProductForWebSite(data) {
    return await api.post('/api/WebSiteProduct/GetAllProductForWebSite', data)
}

//ProductId
export async function GetWebSiteProductDetail(data) {
    return await api.post('/api/WebSiteProduct/GetWebSiteProductDetail', data)
}

//pagequery StockStatus
export async function GetMyTradeList(data) {
    return await api.post('/api/Trade/GetMyTradeList', data)
}


//pagequery QueryDateEnd QueryDateStart
export async function GetStatementOfAccountList(data) {
    return await api.post('/api/Trade/GetStatementOfAccountList', data)
}

//pagequery QueryDateEnd QueryDateStart
export async function GetAssetReport(data) {
    return await api.post('/api/ISPUser/GetAssetReport', data)
}

//pagequery QueryDateEnd QueryDateStart
export async function GetMyTradeDetail(data) {
    return await api.post('/api/Trade/GetMyTradeDetail', data)
}




//pagequery QueryDateEnd QueryDateStart
export async function GetPayDetail(data) {
    return await api.post('/api/Trade/GetPayDetail', data)
}


//pagequery QueryDateEnd QueryDateStart
export async function GetAdditionalDetail(data) {
    return await api.post('/api/Trade/GetAdditionalDetail', data)
}


//pagequery QueryDateEnd QueryDateStart
export async function GetRefundDetail(data) {
    return await api.post('/api/Trade/GetRefundDetail', data)
}


//pagequery QueryDateEnd QueryDateStart
export async function GetTradeRedeemDetail(data) {
    return await api.post('/api/Trade/GetRedeemDetail', data)
}


//pagequery QueryDateEnd QueryDateStart
export async function GetIncomeDetail(data) {
    return await api.post('/api/Trade/GetIncomeDetail', data)
}


//pagequery QueryDateEnd QueryDateStart
export async function GetAssignmentDetail(data) {
    return await api.post('/api/Trade/GetAssignmentDetail', data)
}


//pagequery QueryDateEnd QueryDateStart
export async function GetTradeMaterial(data) {
    return await api.post('/api/Trade/GetTradeMaterial', data)
}

//pagequery QueryDateEnd QueryDateStart
export async function ApplyEmployee(data) {
    return await api.post('/api/ISPUser/ApplyEmployee', data)
}

export  async function UpdateUserInfo(data) {
    return await api.post('/api/ISPUser/UpdateUserInfo',data)
}




