import React, {Component} from 'react';
import './eula.less'




class Eula extends Component {

    constructor(props) {
        super(props);
        this.state = {};

    }

    agree() {
        this.props.agree(true)
    }


    render() {

        return (
            <div className={'eula-box'}>
                <h1 className={'title'}>《信始财富使用协议》</h1>
                <div className='ft14' decode='true'>
                    <p className='ft16'>引言：</p>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className={'xinshi'}>上海信始投资管理有限公司</span>重视您的隐私。您在使用我们的服务（包括我们的网站
                    、信始i财富与信始财富应用）时，我们可能会收集和使用您的信息。我们希望通过本《隐私政策》向您说明在您使用我们的服务时，我们如何收集、使用、储存和分享这些信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。本《隐私政策》与您所使用的信始i财富服务息息相关，我们也希望您能够仔细阅读，并在需要时，按照本《隐私政策》的指引，作出您认为适当的选择。本《隐私政策》之中涉及的相关技术词汇，我们尽量以简明扼要的表述向您解释，并提供了进一步说明的链接，以便您的理解。
                    &nbsp;&nbsp;&nbsp;&nbsp;您使用或继续使用我们的服务，都表示您同意我们按照本《隐私政策》收集、使用、储存和分享您的信息。
                    <p className='ft16'>我们收集的信息：</p>
                    &nbsp;&nbsp;&nbsp;&nbsp;我们提供服务时，可能会收集、储存和使用下列与您有关的信息。如果您不提供相关信息，可能无法注册成为我们的用户、享受我们提供的某些服务，或者即便我们可以继续向您提供一些服务，也无法达到该服务拟达到的效果。
                    <p className='ft16'>您提供的信息：</p>
                    &nbsp;&nbsp;&nbsp;&nbsp;您在注册我们的账户或使用我们的服务时，向我们提供的相关个人信息，例如电话号码等其他方使用我们的服务时所提供有关您的共享信息。
                    <p className='ft16'>我们获取的您的信息：</p>
                    &nbsp;&nbsp;&nbsp;&nbsp;您使用我们服务时我们可能收集如下信息：
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;·设备或软件信息，例如您的移动设备、网页浏览器或您用于接入我们的服务的其他程序所提供的配置信息、您的IP地址和您的移动设备所用的版本和设备识别码；</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;·位置信息指您开启设备定位功能并使用我们基于位置提供的相关服务时，我们收集的有关您位置的信息</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;·您可以通过关闭定位功能随时停止我们对您的地理位置信息的收集。</p>
                    <p className='ft16'>我们获取的您的信息：</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;我们可能将在向您提供服务的过程之中所收集的信息用作下列用途：</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;·向您提供服务；</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;·在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;·帮助我们设计新服务，改善我们现有服务；</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;·软件认证或管理软件升级；</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;·让您参与有关我们产品和服务的调查。</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;为了让我们的用户有更好的体验、改善我们的服务或您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过我们的某一项服务所收集的个人信息，以汇集信息或者个性化的方式，用于我们的其他服务。例如，在您使用我们的一项服务时所收集的您的个人信息，可能在另一服务中用于向您提供特定内容或向您展示与您相关的、而非普遍推送的信息。如我们在相关服务之中提供了相应选项，您也可以主动要求我们将您在该服务所提供和储存的个人信息用于我们的其他服务。针对某些特定服务的特定隐私政策将更具体地说明我们在该等服务中如何使用您的信息。</p>
                    <p className='ft16'>如何访问和控制您的信息：</p>
                    &nbsp;&nbsp;&nbsp;&nbsp;我们将尽量采取适当的技术手段，保证您可以访问、更新和更正您的注册信息或使用我们的服务时提供的其他个人信息。在访问、更新、更正和删除您的个人信息时，我们可能会要求您进行身份验证，以保障您的账户安全。
                    <p className='ft16'>年龄限制：</p>
                    &nbsp;&nbsp;&nbsp;&nbsp;任何年龄层的用户均使用我们的服务。我们建议未成年人鼓励他们的父母或监护人阅读本《隐私政策》，并建议未成年人在提交的个人信息之前寻求父母或监护人的同意和指导。
                    <p className='ft16'>免责条款：</p>
                    &nbsp;&nbsp;&nbsp;&nbsp;<span className={'xinshi'}>上海信始投资管理有限公司</span>在其工作人员正常的判断范围内对所提供产品数据的准确性进行有效的控制。尽管如此，用户了解并同意，由于在产品信息收集过程中需要利用许多非所能控制的数据来源，而且由于数据产品和服务的特殊性，信始i财富不能也不应担保所提供产品信息的准确性，信始i财富应用中展示的产品查询结果仅供参考。对用户由于使用信始i财富所提供的产品信息可能遭受的任何损失不承担责任。
                    <p className='ft16'>本《隐私政策》的适用范围：</p>
                    &nbsp;&nbsp;&nbsp;&nbsp;除某些特定服务外，我们所有的服务均适用本《隐私政策》。这些特定服务将适用特定的隐私政策。该特定服务的隐私政策构成本《隐私政策》的一部分。如任何特定服务的隐私政策与本《隐私政策》有不一致之处，则适用特定服务的隐私政策。
                    <p className='ft16'>请您注意，本《隐私政策》不适用于以下情况：</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;·通过我们的服务而接入的第三方服务（包括任何第三方网站）收集的信息；</p>
                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;·通过在我们服务中进行广告服务的其他公司和机构所收集的信息。</p>
                    <p className='ft16'>本《隐私政策》的修改：</p>
                    &nbsp;&nbsp;&nbsp;&nbsp;我们可能随时修改本《隐私政策》的条款，该等修改构成本《隐私政策》的一部分。如该等修改造成您在本《隐私政策》下权利的实质减少，我们将在修改生效前通过在主页上显著位置提示或向您发送电子邮件或以其他方式通知您，在该种情况下，若您继续使用我们的服务，即表示同意受经修订的本《隐私政策》的约束。
                    本隐私政策解释权归<span className={'xinshi'}>上海信始投资管理有限公司</span>所有。
                </div>
            </div>
        );
    }
}

export default Eula
