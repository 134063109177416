export const User = {
    userInformation: {
        DepartmentId: '',
        DepartmentName: '',
        IfIntegral: false,
        IfPhon: false,
        Token: '',
        UserId: '',
        UserName: '',
        UserStatus: 1,
        State: 0,  // 是否认证，1通过 2驳回
    },
    setUser(res) {
        this.userInformation = res
        sessionStorage.setItem('user', JSON.stringify(res))
    },
    clearUser() {
        this.userInformation = {
            DepartmentId: '',
            DepartmentName: '',
            IfIntegral: false,
            IfPhon: false,
            Token: '',
            UserId: '',
            UserName: '',
            UserStatus: 1,
        }
        sessionStorage.removeItem('user')
    },
    getUser() {
        let user = sessionStorage.getItem('user')
        if (user) {
            try {
                user = JSON.parse(user)
                this.setUser(user)
            } catch (e) {

            }
        }
        return this.userInformation
    },
    isLogin() {
        return this.getUser().UserStatus === 2
    },
    isAuthentication() {
        return this.getUser().State === 1
    }
}
